
import './App.css';
import SignupShopify from './Pages/Onboarding/ShopifyOnboarding/ShopifySignup';
import NotFound from './Pages/NotFound';
import { Provider } from 'react-redux';
import store from './store';
import Settings from './Pages/Settings';
import Messaging from './Pages/Messaging';
import Integration from './Pages/Integration';
import AccountSettings from './Pages/AccountSetup';
import Verify from './Pages/Auth/Verify';
import Users from './Pages/Users';
import ChangePassword from './Pages/User/ChangePassword';
import Notification from './Pages/User/Notification';
import Website from './Pages/Integration/Website';
import Wordpress from './Pages/Integration/Wordpress';
import Shopify from './Pages/Integration/Shopify';
import Invitation from './Pages/Invitation';
import WhatsApp from './Pages/Integration/WhatsApp';
import ChatSection from './Pages/ChatSection';
import ChatScreen from './Pages/chatScreen';

import 'antd/dist/reset.css'
import Questions from './Pages/Questions';
import Pricing from './Pages/payment/Pricing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Cancel from './Pages/payment/Cancel';
import Success from './Pages/payment/Success';
import ShopifyPage from './Pages/Shopify';
import Chatbotsection from './Pages/User/Chatbotsection';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import UserList from './Pages/Leads/Leads';
import SignUpShopify from './Pages/Auth/SignUpShopify';
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./routers/PrivateRouter";
import SuccessShopify from './Pages/payment/SuccessShopify';
import CheckSubscription from './routers/BillingChecker';
import Zapier from './Pages/Integration/Zapier';
import './translations/translations.js'
import Credit from './Pages/User/Credit';
import EmailChatSummaryLogo from './Pages/EmailChatSummary.js';
import CreditTransactionHistory from './Pages/User/TransactionHistory.js';
import { ConfigProvider } from "antd";
import AnalyticDashboard from './Pages/AnalyticDashboardData.js';
import LoginV2 from './Pages/Onboarding/LoginV2.js';
import SingupV2 from './Pages/Onboarding/SignupV2.js';
import PasswordResetV2 from './Pages/Onboarding/PasswordResetV2.js';

import BotTraining from './Pages/BotTraining/BotTrainingPage.js';
import Index from './Pages/Workflow/Index';
import WelcomePage from './Pages/welcomeTour/index';
import MobileRestrictedRoute from './routers/MobileRestrictedRules';


const stripePromise = loadStripe('pk_live_51NXTEUCYSjtwtOyQma5bLqGdcFa1G7Rd3v2ThTaYAuo1nP6225YKgHRuBlJ7Sgx59j5s7ldWJfKE4xOq0YcicxAM00B2iT64vg');

function App() {
  return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryBorderHover: 'black',
              colorPrimaryHover: 'gray',
              colorPrimary: 'black',
              colorPrimaryActive: 'black',
              defaultActiveColor: 'black',
              colorPrimaryTextHover: 'black',
              colorBgContainerDisabled:'gray',
              // Apply custom style when loading is true
              colorPrimaryLoadingBackground: 'inherit', // Keeps the same background color when loading
              colorPrimaryLoadingText: 'inherit', // Keeps the same text color when loading
            }
          }
        }}
      >
    <div className="App">

      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/auth/login" element={<LoginV2 />} />
            <Route path="/" element={<LoginV2/>}/>
            <Route path="/auth/signup" element={<Elements stripe={stripePromise}><SingupV2 /></Elements>}/>
            
            <Route path="/auth/signup/onboarding/shopify" element={<SignupShopify/>}/>


            <Route path="/auth/signupShopify" element={< SignUpShopify/>}/>
            <Route path="/auth/verify" element={<Verify/>}/>
            <Route path="/auth/passwordReset" element={<PasswordResetV2/>}/>
            <Route path="/auth/signup/invitation/:invitation_id" element={<Invitation/>}/>
            <Route path="/auth/payment" element={<Pricing/>}/>
            <Route path="/auth/payment/cancel" element={<Cancel/>}/>
            <Route path="/auth/payment/success" element={<Success/>}/>
            <Route path="/auth/shopifyBilling/success" element={<SuccessShopify/>}/>

            <Route path="*" element={<NotFound />} />
            <Route path="/shopify/callback" element={<SignUpShopify/>} />

            <Route path="/auth/login/v2" element={<LoginV2 />} />
            <Route path="/auth/signup/v2" element={<Elements stripe={stripePromise}><SingupV2 /></Elements>} />
            {/* <Route path="/auth/payment" element={<Pricing/>}/> */}
            <Route path="/auth/passwordReset/v2" element={<PasswordResetV2/>}/>



            <Route element={<PrivateRoute />}>

              <Route element={<CheckSubscription />}>

              <Route
                path="/user/leads"
                element={
                  <MobileRestrictedRoute
                    element={<UserList />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/chatbot"
                element={
                  <MobileRestrictedRoute
                    element={<Chatbotsection />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/messages/:session_id"
                element={
                  <MobileRestrictedRoute
                    element={<Messaging />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/integration"
                element={
                  <MobileRestrictedRoute
                    element={<Integration />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/Adminusers"
                element={
                  <MobileRestrictedRoute
                    element={<Users />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/settings"
                element={
                  <MobileRestrictedRoute
                    element={<BotTraining />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/help"
                element={
                  <MobileRestrictedRoute
                    element={<Questions />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/shopify"
                element={
                  <MobileRestrictedRoute
                    element={<ShopifyPage />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/notification"
                element={
                  <MobileRestrictedRoute
                    element={<Notification />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/website"
                element={
                  <MobileRestrictedRoute
                    element={<Website />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/wordpress"
                element={
                  <MobileRestrictedRoute
                    element={<Wordpress />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/shopify"
                element={
                  <MobileRestrictedRoute
                    element={<Shopify />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/WhatsApp"
                element={
                  <MobileRestrictedRoute
                    element={<WhatsApp />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/Zapier"
                element={
                  <MobileRestrictedRoute
                    element={<Zapier />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/chatsection"
                element={
                  <MobileRestrictedRoute
                    element={<ChatSection />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/integration/chatscreen"
                element={
                  <MobileRestrictedRoute
                    element={<ChatScreen />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/analytics"
                element={
                  <MobileRestrictedRoute
                    element={<AnalyticDashboard />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/edit-profile"
                element={
                  <MobileRestrictedRoute
                    element={<AccountSettings />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/change-password"
                element={
                  <MobileRestrictedRoute
                    element={<ChangePassword />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/email-summary"
                element={
                  <MobileRestrictedRoute
                    element={<EmailChatSummaryLogo />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/transaction-history"
                element={
                  <MobileRestrictedRoute
                    element={<CreditTransactionHistory />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/workflow"
                element={
                  <MobileRestrictedRoute
                    element={<Index />}
                    allowOnMobile={false}
                  />
                }
              />
              <Route
                path="/user/welcome/tour"
                element={
                  <MobileRestrictedRoute
                    element={<WelcomePage />}
                    allowOnMobile={false}
                  />
                }
              />  
              </Route>
              
              <Route
                path="/user/plans"
                element={
                  <MobileRestrictedRoute
                    element={<Elements stripe={stripePromise}><Credit /></Elements>}
                    allowOnMobile={false}
                  />
                }
              />
            </Route>

        </Routes>
      </AuthProvider>
    </Router>

    </div>
    </ConfigProvider>

  );
}

export default App;
