import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { InfoCircleOutlined, DesktopOutlined } from '@ant-design/icons'; // Import Ant Design icons
import colorJawebLogo from '../assets/Onboarding/colorLogo.png';

const MobileRestrictedRoute = ({ element, allowOnMobile }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (isMobile && !allowOnMobile) {
    return (
      <div 
        className="flex flex-col justify-center items-center text-center h-screen"
      >
  

        <DesktopOutlined 
          style={{ fontSize: '40px', color: 'rgb(127, 40, 248)' }} // Icon for desktop requirement

          className="mb-4"
        />
        <h2 className="font-bold text-lg md:text-xl lg:text-2xl">
          Desktop Access Required
        </h2>
        <p className="text-base md:text-lg lg:text-xl mt-4 max-w-md">
          These pages are not available on mobile devices. Please switch to a desktop device to access this page.
        </p>
        
        <InfoCircleOutlined 
          style={{ fontSize: '20px', color: 'rgb(127, 40, 248)', marginTop: '20px' }} // Additional info icon

        />
      </div>
    );
  }

  return element; // Render the provided element if the condition passes
};

export default MobileRestrictedRoute;
