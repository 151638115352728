import React, { useRef, useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Button, Tooltip } from 'antd';
import CustomLayout from '../../layouts/Customlayout';

const { Title, Paragraph } = Typography;

export default function WelcomePage() {
  var UserInfo=JSON.parse(localStorage.getItem('UserObject'))
  console.log(UserInfo.mode)

  const isShopifyUser = UserInfo?.mode === 'shopify';
  const primaryColor =  'rgb(127, 40, 248)';

  return (
    <CustomLayout>
      <div style={{ padding: 40, minHeight: 360 }}>
        <Row gutter={[16, 16]} style={{ padding: '20px', justifyContent: 'center', width: '100%' }}>
          <Col span={24}>
            <Title level={2} style={{ textAlign: 'center', color: primaryColor }}>Welcome to Jaweb</Title>
            <Paragraph style={{ textAlign: 'center', marginBottom: '40px', fontSize: '16px' }}>
              Thank you for choosing our chatbot service! Let's get you started with customizing your chatbot settings to provide the best possible experience for your users. From training data to user engagement, you're in control of creating a chatbot that perfectly fits your needs. 🚀
            </Paragraph>
          </Col>
          <Col xs={20} sm={10} lg={8}>
            <Card
              hoverable
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Title level={4} style={{ color: isShopifyUser ? '#96bf48' : '#52c41a' }}>💬 Training Data</Title>
              <Paragraph>
                Upload and manage your website data to train the chatbot effectively. Ensure it understands your users' needs and provides accurate responses. 📊
              </Paragraph>
              <Tooltip title="Upload data to help your chatbot learn and improve its responses.">
                <Button type="primary" style={{ marginTop: '10px', backgroundColor: primaryColor, borderColor: primaryColor }} onClick={() => window.location.href = '/user/settings'}>Manage Data</Button>
              </Tooltip>
            </Card>
          </Col>
          <Col xs={20} sm={10} lg={8}>
            <Card
              hoverable
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '15px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Title level={4} style={{ color: isShopifyUser ? '#96bf48' : '#faad14' }}>⚙️ Customization</Title>
              <Paragraph>
                Customize the chatbot to suit your preferences. Adjust its settings and behavior to align with your needs for a personalized experience. 
              </Paragraph>
              <Tooltip title="Tailor the chatbot to your liking with customization options.">
                <Button type="primary" style={{ marginTop: '10px', backgroundColor: primaryColor, borderColor: primaryColor }} onClick={() => window.location.href = '/user/chatbot'}>Customize Chatbot</Button>
              </Tooltip>
            </Card>
          </Col>
        </Row>
        {isShopifyUser && (
          <div style={{ marginTop: '40px', textAlign: 'center' }}>
            <Paragraph style={{ marginBottom: '20px', fontSize: '16px', color: primaryColor }}>
              One last thing Embed the chatbot on your store front watch the video below to help do that !
            </Paragraph>
            <div className='flex justify-center items-center'>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/zDCXtYggvUk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            </div>
     
          </div>
        )}
      </div>
    </CustomLayout>
  );
}
