import React, { useEffect, useState, useRef } from 'react';
import './BotTrainingPage.css';
import LinksComponent from './LinksComponent';
import FilesComponent from './FilesComponent';
import CustomLayout from '../../layouts/Customlayout';
import axios from 'axios';
import config from '../../config';
import closeIcon from '../../assets/botTrainingIcons/closeIcon.svg'
import ShopifyLinks from './ShopifyComponent';

const BotTraining = () => {
  const [activeTab, setActiveTab] = useState('links');
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [testingMsgList, setTestingMsgList] = useState([{ 'message': 'Test how good you trained your bot.', 'from_bot': true }])
  const [testingMessage, setTestingMessage] = useState('')
  const [visible, setVisible] = useState(false);

    const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });


    const handleMouseMove = (e) => {
    // Add small offset to prevent tooltip from covering cursor
    setPosition({
      x: e.clientX + 10,
      y: e.clientY + 10
    });
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
    setTestingMsgList([{ 'message': 'Test how good you trained your bot.', 'from_bot': true }])
  };

  const testingMsgListRef = useRef(null);

  useEffect(() => {

    if (testingMsgListRef.current) {
      testingMsgListRef.current.scrollTop = testingMsgListRef.current.scrollHeight;
    }

  }, [testingMsgList?.length]);

  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {

      handleSendTestingMsg()

    }
  }

const handleSendTestingMsg = () => { 

  if (testingMessage.length > 0) {
          setTestingMsgList(prevList => [
        ...prevList, 
        { 'message': testingMessage, 'from_bot': false }
    ]);
  
    setTestingMessage('')
  
      setTestingMsgList(prevList => [
        ...prevList, 
        { 'message': 'Loading....', 'from_bot': true }
    ]);


    let formData = new FormData();
    formData.append('question', testingMessage);
    formData.append('testing_type', activeTab)

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
            'Accept': 'application/json'
        }
    };

    axios.post(`${config.apiUrl}bot-test-website/`, formData, headers)
        .then((res) => {
            setTestingMsgList(prevList => {
                const updatedList = prevList.slice(0, prevList.length - 1);
                return [
                    ...updatedList, 
                    { 'message': res.data.data, 'from_bot': true }
                ];
            });
            setTestingMessage('');
        })
        .catch(error => {
            console.error('Error sending message:', error);
        });
    }
}

var UserInfo=JSON.parse(localStorage.getItem('UserObject'))
console.log(UserInfo.mode)

const showOnlyShopify = UserInfo?.mode ==='shopify'; 

console.log(showOnlyShopify)

  return (

    <CustomLayout>


    <div className="container">
      <div className="header">
   <div className="header-title relative">
      <span>Bot Training</span>
      <span 
        className="header-info ml-2 cursor-help inline-flex items-center justify-center"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onMouseMove={handleMouseMove}
      >
        ⓘ
        {showTooltip && (
          <div 
            className="fixed z-50 w-64"
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`
            }}
          >
            <div className="bg-white rounded-lg shadow-lg border border-gray-200">
              <div className="p-3">
                <h4 className="text-sm font-semibold text-gray-800 mb-2">
                  Bot Training Information
                </h4>
                <p className="text-xs text-gray-600 leading-relaxed">
                  Train your bot by providing website URLs. The bot will crawl through 
                  the pages and learn from the content to provide better responses. 
                  Monitor the training progress and manage your URLs from this dashboard.
                </p>
              </div>
            </div>
          </div>
        )}
      </span>
    </div>
        <button className="test-bot-button" onClick={togglePanel}>
          Test Bot
        </button>
      </div>

      <div className="tab-navigation">
          {[ 
            { id: 'links', label: 'Links', icon: '🔗' },
            { id: 'files', label: 'Files', icon: '📄' },
            ...(showOnlyShopify ? [{ id: 'products', label: 'Products', icon: '🛒' }] : [])
          ].map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`tab-button ${activeTab === tab.id ? 'active' : 'inactive'}`}
            >
              <span className="text-sm">
                {tab.icon} {tab.label}
              </span>
            </button>
          ))}
        </div>

      {/* Tab Content */}
        {activeTab === 'links' && <LinksComponent />}
        {activeTab === 'files' && <FilesComponent />}
        {activeTab === 'products' && <ShopifyLinks />}

        
      {/* Bot View */}
      <div
        className={`testbot-panel ${
          isPanelOpen ? 'testbot-panel--open' : 'testbot-panel--closed'
        }`}
      >
        <div className="testbot-panel-header">
          <div className="testbot-panel-content">
            <h2 className="testbot-panel-title">Bot Preview</h2>
              <button onClick={togglePanel} className="testbot-panel-close-button">
                <img src={ closeIcon} alt="closeIcon" />
            </button>
          </div>
          </div>
          
          <div class="fixed-container" style={{margin:'40px'}}>
            <div class="chat-box" style={{ border: `1px solid #7F28F8` }}>
              <div class="header_chat" style={{ background: '#7F28F8' }}>
                <div>
                  <img src={"https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg" } alt="Logo" />
                </div>
              </div>
              <div class="messages-container" ref={testingMsgListRef}>
                
                {
                  testingMsgList.map((msg, index) => (
                    <div key={index} className={`message ${msg.from_bot ? 'business-message' : 'user-message'}`} style={{ background: msg.from_bot ? '#eee' : '#7F28F8' }}>
                      <p style={{ color: msg.from_bot ? '#000' : '#fff' }}>{msg.message}</p>
                    </div>
                  ))
                }

              </div>
              <div class="input-container">
                <input
                  type="text"
                  placeholder={'Enter message to send'}
                  style={{ border: `1px solid #7F28F8` }}
                  value={testingMessage}
                  onChange={(e) => setTestingMessage(e.target.value)}
                  onKeyPress={ handleKeyPress}
                />
                <button type="button" onClick={handleSendTestingMsg}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="_svg_1cum9_1"><path d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z" fill='#7F28F8'></path></svg>
                </button>
              </div>
              <div className="flex items-center justify-center">
                <a  href="https://jaweb.me/" target="_blank" rel="noopener noreferrer">
                  <span className="text-gray-500">
                    Powered By Jaweb
                  </span>
                </a>
              </div>
            </div>
          </div>


      </div>

      </div>
    </CustomLayout>
    


  );
};

export default BotTraining;
