import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message, Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import config from '../../config';
import '../payment/CSS/Pricing.css'; // Assuming you have shared styles with Pricing
import colorJawebLogo from '../../assets/Onboarding/colorLogo.png';

const SignUpShopify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loader state to track request
  const [userCheckComplete, setUserCheckComplete] = useState(false); // Track if user check is complete
  const [redirecting, setRedirecting] = useState(false); // Track if redirecting to onboarding

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');

    if (shop && code) {
      // Check if the user is new or existing
      axios.post(`${config.apiUrl}shopify/check-user/`, { shop, code })
        .then(response => {
          if (response.data.is_new_user) {
            handleOAuth(shop, code,response.data.is_new_user); // Function for existing users
          } else {
            handleOAuth(shop, code,response.data?.is_new_user); // Function for existing users

          }
        })
        .catch(error => {
          message.error('User Check Error', 3);
          navigate('/auth/login');
        })
        .finally(() => {
          setLoading(false); // Stop loader after user check
          setUserCheckComplete(true); // Mark user check as complete
        });
    } else {
      // If no shop or code, redirect to login
      navigate('/auth/login');
    }
  }, [location, navigate]);

  const handleOAuth = (shop, code,isNewUser) => {
    setLoading(true);

    axios.post(`${config.apiUrl}shopify/callback/`, { shop, code, is_new_user: isNewUser })

      .then(response => {

        if (response.data.access_token){
          handleNewUserRedirect(shop, code,response.data.access_token); // Redirect for new users
        }

        else{
          setLoading(false);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem("Userdata", JSON.stringify(response.data));
          navigate('/user/chatbot');
        }

      })
      .catch(error => {
        setLoading(false);
        message.error('Authorization Error', 3);
        navigate('/auth/login');
      });
  };

  const handleNewUserRedirect = (shop, code,access_token) => {
    setRedirecting(true); // Show redirecting message
    setTimeout(() => {
      navigate(`/auth/signup/onboarding/shopify?shop=${shop}&code=${code}&accessToken=${access_token}`);
    }, 3000); // 3 seconds delay for animation
  };

  return (
    <div className="pricing-section-dark">
      {loading && (
        <div className=" redirecting-message flex flex-col items-center  justify-center h-screen ">
          <Spin size="large" />
        </div>
      )}
      {redirecting && (
        <div className="redirecting-message flex flex-col items-center justify-center h-screen">
          <div className="loader mb-2">
            <Spin size="large" />
          </div>

          <h2 className="text-xl  text-gray">Redirecting to onboarding page...</h2>

        </div>
      )}
    </div>
  );
};

export default SignUpShopify;
