import '../Css/Pricing.css';
import React, { useState } from 'react';
import { Typography, Badge, Calendar, Button, Alert, Space, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import config from '../../../config';

const FreeTrialUsers = () => {
  // Retrieve trial end date from localStorage
  const trialEndDateString = localStorage.getItem('trial_end_date');
  const freeTrialEndDate = trialEndDateString
    ? dayjs(trialEndDateString)
    : dayjs().add(7, 'days'); // Default for testing

  const freeTrialStartDate = freeTrialEndDate.subtract(7, 'days');
  const today = dayjs(); // Current date
  const hasTrialEnded = today.isAfter(freeTrialEndDate); // Check if trial has ended

  const [loadingPlan, setLoadingPlan] = useState(false); // Loading state for plan selection

  // Handle plan selection
  const handlePlanSelection = async (plan) => {
    setLoadingPlan(true);
    try {
      const response = await axios.post(`${config.apiUrl}user/shopifySubscription`, { plan }, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`, // Replace with your token logic
        },
      });

      if (response.data.confirmation_url) {
        // Redirect to the confirmation URL
        window.location.href = response.data.confirmation_url;
      } else {
        message.error('Failed to get confirmation URL. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response?.data?.error) {
        message.error(`Error: ${error.response.data.error.join(', ')}`);
      } else {
        message.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoadingPlan(false);
    }
  };

  // Get list data for calendar events
  const getListData = (value) => {
    let listData = [];
    if (value.isSame(freeTrialEndDate, 'day')) {
      listData = [{ type: 'error', content: 'Your free trial ends today!' }];
    } else if (value.isSame(today, 'day')) {
      listData = [{ type: 'success', content: 'You are on a free trial.' }];
    } else if (value.isSame(freeTrialStartDate, 'day')) {
      listData = [{ type: 'warning', content: 'Your free trial started today.' }];
    }
    return listData;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Typography.Title level={3} className="text-center">
            {hasTrialEnded ? 'Your Free Trial Has Ended' : "You're on a Free Trial"}
          </Typography.Title>

          {!hasTrialEnded && (
            <Alert
              message="Your free trial is active."
              description={`Your free trial started on ${freeTrialStartDate.format(
                'MMMM Do, YYYY'
              )} and will end on ${freeTrialEndDate.format(
                'MMMM Do, YYYY'
              )}. Enjoy our services until then!`}
              type="info"
              showIcon
            />
          )}

          {!hasTrialEnded && <Calendar cellRender={dateCellRender} fullscreen={false} />}

          {hasTrialEnded && (
            <div>
              <Alert
                message="Your free trial has ended."
                description="Choose a plan below to continue enjoying our services."
                type="warning"
                showIcon
              />

              <div className="h-full w-full flex gap-10 mt-6">
                {/* Monthly Plan */}
                <div className="pricing-card-dark">
                  <h3>Shopify Access - Monthly</h3>
                  <div className="price">
                    <span>$49/month</span>
                  </div>
                  <div className="features">
                    <div className="feature-item">
                      <CheckOutlined /> <span>Access to dashboard with analytics</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>Zapier integration</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>WhatsApp integration</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>Real-time messaging</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>AI-powered chatbot</span>
                    </div>
                  </div>
                  <button
                    onClick={() => handlePlanSelection('monthly')}
                    className="action-button-dark"
                    disabled={loadingPlan}
                  >
                    {loadingPlan ? 'Processing...' : 'Choose Monthly'}
                  </button>
                </div>

                {/* Yearly Plan */}
                <div className="pricing-card-dark">
                  <h3>Shopify Access - Yearly (5% off)</h3>
               
                  <div className="price">
                    <span>$558/year</span>
                  </div>
                  <div className="features">
                    <div className="feature-item">
                      <CheckOutlined /> <span>Access to dashboard with analytics</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>Zapier integration</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>WhatsApp integration</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>Real-time messaging</span>
                    </div>
                    <div className="feature-item">
                      <CheckOutlined /> <span>AI-powered chatbot</span>
                    </div>
                  </div>
                  <button
                    onClick={() => handlePlanSelection('yearly')}
                    className="action-button-dark"
                    disabled={loadingPlan}
                  >
                    {loadingPlan ? 'Processing...' : 'Choose Yearly'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Space>
      </div>
    </div>
  );
};

export default FreeTrialUsers;
