import React, { useEffect, useState } from 'react';
import "../../../src/Pages/Css/Integration.css";
import errorImg from "../../assets/fluent-mdl2_error.png";
import facebook_icon from "../../assets/image 655.png";
import { Link } from 'react-router-dom';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './Settingsoptions';
import axios from 'axios';
import { MdVerified } from "react-icons/md";
import config from '../../config';
import { Modal, Button, Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { getCountryCallingCode } from 'libphonenumber-js'
import { Spin } from "antd";
import { useTranslation } from 'react-i18next';


export default function WhatsApp() {
  const [isLoggedId, setIsLoggedIn] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track the current step
  const [fbObject, setFbObject] = useState({});
  const [creatingSubaccount, setCreatingSubaccount] = useState(false);
  const [subAccountInfo, setSubAccountInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const [value, setValue] = useState()
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [countryCode, setCountryCode] = useState()

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const [companyName, setCompanyName] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [companyWebsite, setCompanyWebsite] = useState("")

  const [validationErrors, setValidationErrors] = useState({});


  const { t } = useTranslation();
  const integrationTranslations = t("integration")

  useEffect(() => {

    if (JSON.parse(localStorage.getItem('UserObject')).phone_number_id) {
      setCurrentStep(3)
      setRegistrationSuccess(true)
    } else {
      console.log('no');
    }

  }, [])


  const LoadingSpinner = () => {
    return <div>Loading...</div>;
  };
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setProfileName('');
    setPhoneNumber('');
  };

  const handleProfileNameChange = (e) => {
    setProfileName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleFormSubmit = () => {
    // Handle form submission logic here, e.g., send data to backend
    console.log(`Profile Name: ${profileName}, Phone Number: ${phoneNumber}`);
    closeModal(); // Close modal after submission
  };

  // console.log("🚀 ~ WhatsApp ~ 22222", isLoggedId);
  const embeddedSignupInfoListener = (event) => {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH" || data.event === "FINISH_ONLY_WABA") {
          const { phone_number_id, waba_id } = data.data;
          console.log({ phone_number_id, waba_id });
          setFbObject({ phone_number_id, waba_id });


          setIsLoggedIn(true);
          setCurrentStep(2); // Move to the next step after login

        } else {
          const { current_step } = data.data;
          console.log(`User did not finish ESU. Last step: ${current_step}`);
        }
      }
    } catch {
      console.log('catched error');
      return;
    }
  };

  const handleLogin = () => {
    onLoginClick();
    // setIsLoggedIn(true);
    // setCurrentStep(2); // Move to the next step after login
  };

  const handlePhoneNumber = (e) => {
    if (e) {
      setPhoneNumber(e)
      setValidPhoneNumber(isValidPhoneNumber(e))
      if (!isValidPhoneNumber(e)) {
        // setErrorMessage('Invalid phone number.')
      }
    } else {
      // setErrorMessage('Enter your phone number')
    }
  }

  const handleCountryChange = (country) => {
    // Extract country code from the selected country object
    const selectedCountryCode = country ? `+${getCountryCallingCode(country)}` : ''
    setCountryCode(selectedCountryCode)
  }
  const onLoginClick = () => {
    // setyCurrentStep(2);
    setIsLoggedIn(true);
    window.FB.login(
      function (response) {
        console.log("🚀 ~ onLoginClick ~ response:", response);
      },
      {
        config_id: "399880686267907",
        auth_type: "rerequest",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2,
          setup: {
            solutionID: "969683498163827",
          }
        }
      }
    );
  };

  const handleCreateSender = () => {
    setLoading(true);
    console.log('creating sender.........................');
    let formData = new FormData();
    formData.append('friendly_name', 'Test Sender');

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.post(`${config.apiUrl}whatsapp-subaccount/`, formData, headers)
      .then((res) => {
        console.log('sender created----------------------', res);
        setSubAccountInfo(res.data.response);
        setLoading(false);
        setShowTick(true);
        setIsModalVisible(true); // Open the modal after successful response
        // Assuming setCurrentStep(3) was causing unexpected redirect,
        // make sure this logic is not causing the issue
        // setCurrentStep(3); // Move to the next step after creating sender
      })
      .catch((error) => {
        console.error('Error creating sender:', error);
        setLoading(false);
        // Handle error scenarios as needed
      });
  };


  const validateProfileName = (name) => {
    return name.trim().length > 0 && name.length <= 50;
  };

  const validateCompanyName = (name) => {
    return name.trim().length > 0 && name.length <= 100;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // const validateWebsite = (website) => {
  //   const websitePattern = /^(https?:\/\/)?[^\s$.?#].[^\s]*$/i;
  //   return !website && websitePattern.test(website);
  // };

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^\+?[1-9]\d{1,14}$/; // E.164 format
    return phonePattern.test(phoneNumber);
  };


  const handleRegisterSender = () => {

    const newErrors = {};

    if (!validateProfileName(profileName)) newErrors.profileName = 'Invalid profile name.';
    if (!validateCompanyName(companyName)) newErrors.companyName = 'Invalid company name.';
    if (!validateEmail(companyEmail)) newErrors.companyEmail = 'Invalid email address.';
    // if (!validateWebsite(companyWebsite)) newErrors.webLinks = 'Invalid website URL.';
    if (!validatePhoneNumber(phoneNumber)) newErrors.phoneNumber = 'Invalid phone number.';

    if (Object.keys(newErrors).length === 0) {

      setLoading(true)

      // console.log(phoneNumber);
      const newPhoneNumber = phoneNumber.replace('+', '')
      // console.log(newPhoneNumber);

      let formData = new FormData();
      formData.append('sub_account_id', subAccountInfo.sid);
      formData.append('sub_account_token', subAccountInfo.auth_token);
      formData.append('waba_id', fbObject.waba_id); // embedded signup
      formData.append('sender_id', newPhoneNumber); // input box
      formData.append('profile_name', profileName); // input box

      formData.append('company_name', companyName); 
      formData.append('company_email', companyEmail); 
      formData.append('company_website', companyWebsite); 

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${JSON.parse(localStorage.getItem('Userdata')).token}`,
          'Accept': 'application/json'
        }
      };

      axios.post(`${config.apiUrl}whatsapp-sender-register/`, formData, headers)
        .then((res) => {
          console.log('sender registered----------------------', res);
          setLoading(false)
          setRegistrationSuccess(true); // Set registration success flag
          setCurrentStep(3); // Move to the last step after successful registration
        })
        .catch((error) => {
          console.error('Error registering sender:', error);
          // Handle error scenarios as needed
        });


    } else {
      setValidationErrors(newErrors);
      console.log(newErrors);
      
    }



  };

  window.addEventListener("message", embeddedSignupInfoListener);
  // useEffect(()=>{
  //   // window.removeEventListener("message", embeddedSignupInfoListener);
  // },[isLoggedId])

  return (
    <CustomLayout>
      <div className='flex flex-col md:flex-row gap-6'>
        <div className='md:w-1/6'>
          <SettingOption />
        </div>

        <div className='flex-1'>
          <div className='container custom-wrapper'>
            <div className="stepper-wrapper">
              <div className={`stepper-item ${currentStep >= 1 ? 'completed' : ''}`}>
                <div className="step-counter">{currentStep > 1 ? <span>&#10003;</span> : '1'}</div>
                <div className="step-name">{ integrationTranslations.whatsappTab.connectFbTab}</div>
              </div>
              <div className={`stepper-item ${currentStep >= 2 ? 'completed' : currentStep === 2 ? 'active' : ''}`}>
                <div className="step-counter">{currentStep > 2 ? <span>&#10003;</span> : '2'}</div>
                <div className="step-name">{ integrationTranslations.whatsappTab.connectBusinessTab}</div>
              </div>
              <div className={`stepper-item ${currentStep >= 3 ? 'completed' : currentStep === 3 ? 'active' : ''}`}>
                <div className="step-counter">{currentStep > 3 ? <span>&#10003;</span> : '3'}</div>
                <div className="step-name">{ integrationTranslations.whatsappTab.allSetTab}</div>
              </div>
            </div>

            {currentStep === 1 ? (
              <>
                <div className='errorBox-wrapper flex'>
                  <img src={errorImg} alt="errorImg" />
                  <h2 className='errorText'>{ integrationTranslations.whatsappTab.integrationNote}</h2>
                </div>
                <div className='custom-create'>
                  <h2 className='create_text'>{ integrationTranslations.whatsappTab.heading }</h2>
                  <p className='cus_text flex'>{ integrationTranslations.whatsappTab.subHeading1}</p>
                  <p className='cus_text'>{ integrationTranslations.whatsappTab.subHeading2}</p>
                </div>
                <ul className="custom-list">
                  <li className='cus_text_li'>{ integrationTranslations.whatsappTab.note1}</li>
                  <li className='cus_text_li'>{ integrationTranslations.whatsappTab.note2}</li>
                  <li className='cus_text_li note'><span>{ integrationTranslations.whatsappTab.note}</span>{ integrationTranslations.whatsappTab.note3}</li>
                </ul>
                <div className=' flex custom_btns'>
                  <button onClick={handleLogin} style={{
                    backgroundColor: '#1877f2',
                    border: '0',
                    borderRadius: '4px',
                    color: '#fff',
                    cursor: 'pointer',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    height: '40px',
                    padding: '0 24px'
                  }}>
                    { integrationTranslations.whatsappTab.fbLogin }
                  </button>
                  <button className='help_btn'>{ integrationTranslations.whatsappTab.help}</button>
                </div>
              </>
            ) : (
              <div className='afterLogin'>

                {currentStep === 2 &&
                  <div>
                    <div className='custom-create'>
                      <h2 className='create_text'>{ integrationTranslations.whatsappTab.connectBusinessTab}</h2>
                        <p className='cus_text flex'> { integrationTranslations.whatsappTab.createNote}</p>
                    </div>

                    <div className=' custom_btns'>

                      <button
                        style={{
                          backgroundColor: 'rgb(127, 40, 248)',
                          border: '0',
                          borderRadius: '4px',
                          color: '#fff',
                          cursor: 'pointer',
                          fontFamily: 'Helvetica, Arial, sans-serif',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          height: '40px',
                          padding: '0 24px'
                        }}
                        onClick={handleCreateSender}
                        disabled={loading}
                      >
                        {loading ? <LoadingSpinner /> : showTick ? <CheckCircleOutlined /> : 'Create Sender'}
                      </button>

                      <Modal
                        title="Register Sender"
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={[
                          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                            { integrationTranslations.whatsappTab.cancelBtn}
                          </Button>,
                          <Button key="submit" style={{ backgroundColor: 'rgb(127, 40, 248)', color: 'white' }} onClick={handleRegisterSender}>

                            {
                              loading ? <Spin className='spin_bg' /> : 'Submit'
                            }

                          </Button>,
                        ]}
                      >
                          <label htmlFor="profileName">{ integrationTranslations.whatsappTab.profileName}</label>
                        <Input
                          id="profileName"
                          value={profileName}
                          onChange={handleProfileNameChange}
                          placeholder={ integrationTranslations.whatsappTab.profileNamePlaceholder } 
                          />
                          {validationErrors.profileName && <div style={{ color: 'red' }}>{validationErrors.profileName}</div>}
                          <br /><br />
                          

                          <label htmlFor="companyName">{ integrationTranslations.whatsappTab.companyName}</label>
                        <Input
                          id="companyName"
                          value={companyName}
                          onChange={(e)=> setCompanyName(e.target.value)}
                          placeholder={ integrationTranslations.whatsappTab.companyNamePlaceholder } 
                          />
                          {validationErrors.companyName && <div style={{ color: 'red' }}>{validationErrors.companyName}</div>}
                          <br /><br />
                          

                          <label htmlFor="companyEmail">{ integrationTranslations.whatsappTab.companyEmail}</label>
                        <Input
                          id="companyEmail"
                          value={companyEmail}
                          onChange={(e)=> setCompanyEmail(e.target.value)}
                          placeholder={ integrationTranslations.whatsappTab.companyEmailPlaceholder } 
                          />
                          {validationErrors.companyEmail && <div style={{ color: 'red' }}>{validationErrors.companyEmail}</div>}
                        <br /><br />

                          
                          {/* <label htmlFor="companyWebsite">{ integrationTranslations.whatsappTab.companyWebsite}</label>
                        <Input
                          id="companyWebsite"
                          value={companyWebsite}
                          onChange={(e)=> setCompanyWebsite(e.target.value)}
                          placeholder={ integrationTranslations.whatsappTab.companyWebsitePlaceholder } 
                          />
                          {validationErrors.webLinks && <div style={{ color: 'red' }}>{validationErrors.webLinks}</div>}
                        <br /><br /> */}

                        <label >{ integrationTranslations.whatsappTab.phoneNumber}</label>

                        <PhoneInput
                          defaultCountry='US'
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                          onCountryChange={handleCountryChange}
                          placeholder={ integrationTranslations.whatsappTab.phoneNumberPlaceholder}
                          className='phone_input'
                          />
                          {validationErrors.phoneNumber && <div style={{ color: 'red' }}>{validationErrors.phoneNumber}</div>}

                      </Modal>

                    </div>

                  </div>
                }
                {
                  currentStep === 3 && registrationSuccess && (
                    <button>  <div className='cus-after-wid-upper flex setWrapper wrap_textZ'>
                      <h2 className='errorText mb-0 after-text allset'>{ integrationTranslations.whatsappTab.allSet}</h2>
                      <MdVerified className='after-text-icon mb-0' />
                    </div>
                      <div className='custom-create cus-after-wid'>
                        <p className='cus_text flex after-text text-start'>{ integrationTranslations.whatsappTab.successNote}</p>
                      </div>


                    </button>
                  )
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </CustomLayout>
  );
}
