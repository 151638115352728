import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Integration from "../Pages/Integration";
import { notification } from "antd";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";


i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  returnObjects: true,
  lng:localStorage.getItem('i18nextLng') || 'en',
  resources: {
    en: {
      translation: {
        customization: {
          title: "Chat Interface",
          note: "Note : Applies when embedded on website",
          initialMsg: "Initial Messages",
          suggestedMsg: "Suggested Messages",
          chatbotProfile: "Update Chatbot profile picture",
          theme: "Theme",
          themeNote: "*If the changes here don't show up immediately on your website try clearing your browser cache or use incognito. (New users will see the changes immediately)*",
          chatbotIcon: "Update Chatbot Icon",
          disableForm: "Disable Chatbot Form",
          humanTakeover: "Human takeover (Global)",
          save: "Save",
          sendMsgPlaceholder: "Send Message...",
          emailSummary: "Send Chat Summary (Email)"
          
        },
        leads: {
          name: "Name",
          date: "Date / Time",
          country: "Country",
          phone: "Phone",
          action: "Action",
          exportExcel: "Export as Excel",
        },

        analytics: {
          chatbot: {
            label: "Chatbot Analysis",
            totalChatlog: "Total Chatlog",
            uniqueUser: "Unique User",
            avgMessagePerSession: "Avg. Message Per Session",
            userRetention: "Retained User",
          },
          whatsapp: {
            label: "Whatsapp Analysis",
            totalChatlog: "Total Chatlogs",
            uniqueUser: "Unique User",
            avgMessagePerSession: "Avg. Message Per Session",
            userRetention: "Retained User",
          },
          lineGraph: {
            label: "Total Users",
            totalChats: "Total Chats",
            thisYear: "This Year",
            lastYear: "Last Year",
          },
          barGraph: {
            label: "User Satisfaction",
            thisYear: "This Year",
            lastYear: "Last Year",
          },
          piechart: {
            label: "Feedback / Resolutions Rate",
            rating_1: "Confirmed Resolutions",
            rating_2: "Assumed Resolutions",
            rating_3: "No Resolutions",
            rating_4: "Unclear",
            rating_5: "Need Improvement",
          },
          areaChart: {
            label: "Lead Conversion",
            totalOrder: "Total Orders"
          }
        },

        messages: {
          activeChats: "Active Chats",
          chats: "Chats",
          whatsapp: "Whatsapp",
          takecontrol: "Take Control",
          typePlaceholder: "Type Here and Press Enter",
          customerInfo: "Customer Info",
          contactNo: "Contact No",
          location: "Location",
          email: "Email",
          id: "Id",
          sendChatMsgPlaceholder: "Type Here And Press Enter",
          readMore: "... Read More",
          readLess: "Read Less",

        },
        integration: {
          website: "Website",
          wordpress: "Wordpress",
          whatsapp: "Whatsapp",
          zapier: "Zapier",
          introduction: "Introduction",
          introductionNote: "The cutting-edge chatbot plugin, is your company's gateway to seamless communication and enhanced customer engagement. Designed to revolutionize how businesses interact with their audience, Jaweb brings the power of artificial intelligence to your fingertips",
          introductionSubNote: "Follow the steps provided to install Jaweb plugin on your website . 😊 Enjoy!",
          supportedPlateforms: " Supported Platforms",

          websiteNote: "Note: This installation works for pure html files index.html",
          step1: "Follow the steps below to install Jaweb Plugin",
          step2_1: "First, create a ",
          step2_2: "file (choose any suitable name for your file)",
          step3: "In your body Tag Add the code below",
          step4: "Also add the code below in your Body tag as well",
          userIdentification: "User Identification",
          step5: "You can put this at the last line of the project",
          css: "CSS",
          step6: "This should be the last thing to put in your Head Tag",
          
          wordpressInstall: "Steps to Install on WordPress",
          followSteps: "Follow these steps to install on WordPress.",
          wstep1: "Click on the button below to download the plugin",
          wstep2: "Go to your WordPress admin page.",
          wstep3: "Go to the 'Plugins' section.",
          wstep4: "Click on 'Upload Plugin'.",
          wstep5: "Upload the downloaded ZIP file (plugin).",
          wstep6: "Activate the plugin.",
          wstep7: "Enter your username after activation.",
          downloadBtn: "Download",

          whatsappTab: {

            connectFbTab: "Connect to Facebook",
            connectBusinessTab: "Connect to Business",
            allSetTab: "All Set",

            integrationNote: "Follow the steps below to set up your Jaweb Account. Or, we have also sent you an email with a link so that you can start this process any time.",
            heading: "Create WhatsApp Business Account",
            subHeading1: "Your Business is required to create a WhatsApp Business Account in Facebook, or use your existing one if you have any.",
            subHeading2: "Facebook has a strict policy on reviewing your business information in the WhatsApp business account. Please make sure you have the information below prepared before proceeding.",
            note: "Note :",
            note1: "Your company’s legal name and address registered with local authorities.",
            note2: "Your WhatsApp Profile display name must match your company’s legal name.",
            note3: "A phone number that does not have an existing WhatsApp account.",
            fbLogin: "Login with Facebook",
            help: "Need Help?",
           
            createNote: "Hey there! You're almost there, Please click on the button below to connect your whatsapp business account with jaweb and register yourself as a Whatsapp message sender",
            cancelBtn: "Cancel",
            profileName: "Profile Name :",
            profileNamePlaceholder: "Enter profile name",
            phoneNumber: "Phone Number :",
            phoneNumberPlaceholder: "Enter phone number",
            companyName: "Company Name",
            companyNamePlaceholder: "Enter company name",
            companyEmail: "Company Email Address",
            companyEmailPlaceholder: "Enter company email address",
            companyWebsite: "Company Website",
            companyWebsitePlaceholder: "Enter company website",

            allSet: "All Set thank you!",
            successNote: "Your Facebook account has been connected with Jaweb. As it is a customer initiated conversation so your customers can contact you through Whatsapp. Once you receive any message from a customer then you'll be able to continue the conversation."

          }
        },
        teams: {
          email: "Email",
          status: "Status",
          permission: "Permission",
          action: "Action",
          inviteMember: "Invite Member",
          
        },
        accountSetting: {
          edit: "Edit Profile",
          billing: "Billing",
          changePass: "Current Password",
          changePassSubmit:"Submit",
          notification: "Notifications",
          username: "Username",
          email: "Email",
          firstName: "First Name",
          lastName: "Last Name",
          region: "Region",
          language: "Language",
          saveChange: 'Save Changes',
          
          billing: "Billing",
          cardType: "Card Type",
          cardNo: "Card Number",
          expire: "Expire on",
          addNew: "Add new Card",
          actions: "Actions",

          buycredit: "Credit",

          changePass: "Current Password",
          newPass: "New Password",
          confirmPass: "Confirm Password",
          changePassbutton:"Submit",
          
          emailNoti: "Email Notifications",
          emailNotiNote: "You will receive daily email notifications.",
          newProductNoti: "New Product",
          newProductNotiNote: "You will receive notifications when a new product arrives.",
          chatNoti: "Chat",
          chatNotiNote: "You will receive notifications when someone assigns you a chat",
          

          plans: {
            label: "Plans",

            stripe: {
              heading: "Manage Subscription",
              description: "Click the Manage button below to view or update your subscription settings. Use this option to modify your plan, update payment details, or cancel your subscription as needed.",
              managebtn: "Manage"
            },
            shopifyPlan: {
              shopifyAccess: "Shopify Access",
              shopifyAccessYearly: "5% off",
              perMonth: "Per Month",
              perYear: "Per Year",
              features: {
                feature1: "10,000 chatbot credits/month",
                feature2: "12,500 whatsapp credits/month",
                feature3: "11,000,000 characters/chatbot",
                feature4: "Unlimited links to train on",
                feature5: "Access to Chatlogs",
                feature6: "Integrations",
                feature7: "Access to Human Interference",
                feature8: "Live Chatbot On Your Shopify Store",
              },
            },
            webPlan: {
              webAccess: "Web Access",
              upgrade: "Upgrade",
              switch_back: "Change to Monthly Plan",
              current_plan: "Current Plan",
              webAccessYearly: "5% off",
              perMonth: "Per Month",
              perYear: "Per Year",
              features: {
                feature1: "10,000 chatbot credits/month",
                feature2: "12,500 whatsapp credits/month",
                feature3: "Access to Chatlogs",
                feature4: "400,000 characters/chatbot",
                feature5: "Access to Human Interference",
                feature6: "Integrations",
                feature7: "Access to follow up feature",
              },
            },
            credits: {

              chatbot: {
                heading: "Extra chatbot message credits",
                subHeading: "chatbot credits",
                per: "Per",
                modalHeading: "Recharge chatbot credits",
                modalsubHeading: "Extra credits I want to buy:",
                modalPerCharge: "Total per charge"

              },
              whatsapp: {
                heading: "Extra whatsapp message credits",
                subHeading: "whatsapp credits",
                modalHeading: "Recharge whastapp credits",
                modalsubHeading: "Extra whastapp credits I want to buy:",
                modalPerCharge: "Total per charge"
              },
              branding: {
                heading: "Remove 'Powered By Jaweb",
                subHeading: "Remove the Jaweb branding from the iframe and widget",
                pay: 'Pay $19',
                paid: "Paid"
              },
              upgradeBtn: "Upgrade"
            }
          },
          emailSummary: {
            label: "Email Summary",
            heading: "Set company logo for email summary",
            subHeading: "Note: Please use .png format with white background",
            uploadImgBtn: "Upload Image"
          },
          transactionHistory: {
            label: "Transaction History",
            table: {
              paymentAmt: "Payment Amount (USD)",
              totalCredit: "Total Credit",
              creditType: "Credit Type",
              date: "Date",
            }
          }

        },
        botTraining: {
          heading: "Tailor your bot's intelligence to perfection",
          subHeading: "by uploading PDF documents for specialized learning or a website link",
          uploadFile: "Upload Files",
          uploadFileNote: "Select and upload the files of your choice",
          fetchLinksBtn: "Fetch Links",
          pdf: "PDF",
          links: "Links",
          source: "Source",
          chars: "detected characters",
          totalChars: "Total detected characters",
          limit: "Limit",
          trainBot: "Train Chatbot",
          chooseFile: "Choose a file or drag & drop it here",
          urlLink: "or paste the URL below",
          
          includeLinks: "Included links",
          confirmDltBtn: "Confirm Delete",
          yesBtn: "Yes",
          noBtn: "No",

          confirmDltBtn: "Are you sure you want to delete this?",
          confirmDltAll: "Confirm Delete All",
          allLinksDltBtn: "Are you sure you want to delete all website links?",
          closeBtn: "Close"
          
        },
        navbar: {
          customization: "Customization",
          leads: "Leads",
          analytics: "Analytics",
          message: "Message",
          integration: "Integration",
          accSetting: "Account Setting",
          workFlow:"WorkFlow",
          botTraining: "Bot Training",
          shopify:"Shopify",
          team:"Team",
          help:"Help",
          Workflow:"Workflow",
          logout:"Logout",

        },
        header: {
          whatsapp: "Whatsapp",
          chatbot: "Chatbot",
        }
      }
    },
    ar: {
      translation: {
        customization: {
          title: "واجهة المحادثة",
          note: "ملاحظة:سترى التعديلات عند ربط جاوب مع موقعك الالكتروني",
          initialMsg: "الرسائل الأولية",
          suggestedMsg: "الرسائل المقترحة",
          chatbotProfile: "تحديث صورة الملف الشخصي للمحادثة الآلية",
          theme: "المظهر",
          themeNote: "إذا لم تظهر التغييرات هنا على الفور على موقعك الالكتروني بك، حاول مسح ذاكرة التخزين المؤقت للمتصفح أو استخدم وضع التصفح المتخفي. (سيتمكن المستخدمون الجدد من رؤية التغييرات على الفور)*",
          chatbotIcon: "تحديث أيقونة للمحادثة الآلية",
          disableForm: "تعطيل نموذج روبوت المحادثة",
          humanTakeover: "الاستيلاء البشري (عالميًا)",
          save: "حفظ",
          sendMsgPlaceholder: "إرسال رسالة...",
          emailSummary: "إرسال ملخص الدردشة (البريد الإلكتروني)",
          

        },
        leads: {
          name: "اسم",
          date: "التاريخ / الوقت",
          country: "دولة",
          phone: "هاتف",
          action: "فعل",
          exportExcel: "تصدير بصيغة Excel",
          
        },

        analytics: {
          chatbot: {
            label: "تحليل روبوت الدردشة",
            totalChatlog: "إجمالي سجل الدردشة",
            uniqueUser: "المستخدم الفريد",
            avgMessagePerSession: "متوسط ​​الرسالة لكل جلسة",
            userRetention: "المستخدم المحتفظ به",
          },
          whatsapp: {
            label: "تحليل الواتس اب",
            totalChatlog: "مجموع سجلات الدردشة",
            uniqueUser: "المستخدم الفريد",
            avgMessagePerSession: "متوسط ​​الرسالة لكل جلسة",
            userRetention: "المستخدم المحتفظ به",
          },
          lineGraph: {
            label: "إجمالي المستخدمين",
            totalChats: "مجموع الدردشات",
            thisYear: "هذا العام",
            lastYear: "العام الماضي",
          },
          barGraph: {
            label: "رضا المستخدم",
            thisYear: "هذا العام",
            lastYear: "العام الماضي",
          },
          piechart: {
            label: "معدل التعليقات / القرارات",
            rating_1: "قرارات مؤكدة",
            rating_2: "القرارات المفترضة",
            rating_3: "لا يوجد قرارات",
            rating_4: "غير واضح",
            rating_5: "بحاجة إلى تحسين",
          },
          areaChart: {
            label: "تحويل العملاء المحتملين",
            totalOrder: "مجموع الطلبات"
          }
        },

        messages: {
          activeChats: "الدردشات النشطة",
          chats: "الدردشات",
          whatsapp: "واتساب",
          takecontrol: "سيطر على الأمور",
          typePlaceholder: "اكتب هنا واضغط على Enter",
          customerInfo: "معلومات العميل",
          contactNo: "رقم الاتصال",
          location: "موقع",
          email: "بريد إلكتروني",
          id: "بطاقة تعريف",
          sendChatMsgPlaceholder: "اكتب هنا واضغط على Enter",
          readMore: "... اقرأ المزيد",
          readLess: "اقرأ أقل",

        },
        integration: {
          website: "موقع إلكتروني",
          wordpress: "ووردبريس",
          whatsapp: "واتساب",
          zapier: "زابير",
          introduction: "مقدمة",
          introductionNote: "يُعد برنامج الدردشة الآلي المتطور بمثابة بوابة شركتك للتواصل السلس وتعزيز مشاركة العملاء. صُمم برنامج Jaweb لإحداث ثورة في كيفية تفاعل الشركات مع جمهورها، حيث يضع قوة الذكاء الاصطناعي في متناول يديك",
          introductionNote: "اتبع الخطوات المقدمة لتثبيت البرنامج المساعد Jaweb على موقع الويب الخاص بك. 😊 استمتع!",
          supportedPlateforms: " المنصات المدعومة",

          websiteNote: "ملاحظة: يعمل هذا التثبيت على ملفات html النقية index.html",
          step1: "اتبع الخطوات التالية لتثبيت Jaweb Plugin",
          step2_1: "أولاً، قم بإنشاء",
          step2_2: "الملف (اختر أي اسم مناسب لملفك)",
          step3: "في جسمك أضف الكود أدناه",
          step4: 'أضف أيضًا الكود أدناه في علامة "الجسم" الخاصة بك أيضًا',
          userIdentification: "تحديد هوية المستخدم",
          step5: "يمكنك وضع هذا في السطر الأخير من المشروع",
          css: "سي اس اس",
          step6: "يجب أن يكون هذا هو آخر شيء تضعه في علامة الرأس الخاصة بك",
          
          wordpressInstall: "خطوات التثبيت على الووردبريس",
          followSteps: "اتبع الخطوات التالية للتثبيت على WordPress.",
          wstep1: "انقر على الزر أدناه لتنزيل البرنامج الإضافي",
          wstep2: "انتقل إلى صفحة إدارة WordPress الخاصة بك.",
          wstep3: 'انتقل إلى قسم "المكونات الإضافية".',
          wstep4: 'انقر فوق "تحميل البرنامج الإضافي".',
          wstep5: "قم بتحميل ملف ZIP الذي تم تنزيله (المكون الإضافي).",
          wstep6: "تفعيل البرنامج الإضافي.",
          wstep7: "أدخل اسم المستخدم الخاص بك بعد التنشيط.",
          downloadBtn: "تحميل",

          whatsappTab: {

            connectFbTab: "الاتصال بالفيسبوك",
            connectBusinessTab: "الاتصال بالعمل",
            allSetTab: "كل شيء جاهز",

            integrationNote: "اتبع الخطوات التالية لإعداد حساب Jaweb الخاص بك. أو أرسلنا لك أيضًا بريدًا إلكترونيًا يحتوي على رابط حتى تتمكن من بدء هذه العملية في أي وقت.",
            heading: "إنشاء حساب WhatsApp Business",
            subHeading1: "يجب على شركتك إنشاء حساب WhatsApp Business على Facebook، أو استخدام حسابك الحالي إذا كان لديك أي.",
            subHeading2: "لدى Facebook سياسة صارمة بشأن مراجعة معلومات عملك في حساب WhatsApp التجاري. يرجى التأكد من إعداد المعلومات أدناه قبل المتابعة.",
            note: "ملحوظة :",
            note1: "الاسم القانوني لشركتك وعنوانها مسجلين لدى السلطات المحلية.",
            note2: "يجب أن يتطابق اسم العرض الخاص بملفك الشخصي على WhatsApp مع الاسم القانوني لشركتك.",
            note3: "رقم هاتف لا يحتوي على حساب WhatsApp موجود.",
            fbLogin: "تسجيل الدخول باستخدام الفيسبوك",
            help: "هل تحتاج إلى مساعدة؟",
           
            createNote: "مرحبًا! لقد اقتربت من الوصول، يُرجى النقر على الزر أدناه لربط حساب الأعمال الخاص بك على WhatsApp بـjaweb وتسجيل نفسك كمرسل رسائل على WhatsApp",
            cancelBtn: "يلغي",
            profileName: "اسم الملف الشخصي :",
            profileNamePlaceholder: "أدخل اسم الملف الشخصي",
            phoneNumber: "رقم التليفون :",
            phoneNumberPlaceholder: "أدخل رقم الهاتف",

            allSet: "كل شيء على ما يرام منك!",
            successNote: "تم ربط حسابك على Facebook بـ Jaweb. نظرًا لأنه محادثة يتم بدءها من قبل العميل، فيمكن لعملائك التواصل معك عبر WhatsApp. بمجرد استلام أي رسالة من أحد العملاء، ستتمكن من متابعة المحادثة."

          }


        },
        teams: {
          email: "بريد إلكتروني",
          status: "حالة",
          permission: "إذن",
          action: "فعل",
          inviteMember: "دعوة العضو",
          
        },
        accountSetting: {
          edit: "تعديل الملف الشخصي",
          billing: "الفواتير",
          changePass: "تغيير كلمة المرور",
          changePassSubmit:"يُقدِّم",
          notification: "إشعار",
          username: "اسم المستخدم",
          email: "بريد إلكتروني",
          firstName: "الاسم الأول",
          lastName: "اسم العائلة",
          region: "منطقة",
          saveChange: 'حفظ التغييرات',
          language: "لغة",
          
          billing: "الفواتير",
          cardType: "نوع البطاقة",
          cardNo: "رقم البطاقة",
          expire: "تنتهي صلاحيتها في",
          addNew: "إضافة بطاقة جديدة",
          actions: "الأفعال",
          buycredit: '"الأفعال',

          changePass: "تغيير كلمة المرور",
          newPass: "كلمة المرور الجديدة",
          confirmPass: "تأكيد كلمة المرور",
          
          emailNoti: "إشعارات البريد الإلكتروني",
          emailNotiNote: "سوف تتلقى إشعارات بالبريد الإلكتروني يوميًا.",
          newProductNoti: "منتج جديد",
          newProductNotiNote: "سوف تتلقى إشعارات عند وصول منتج جديد.",
          chatNoti: "الدردشات",
          chatNotiNote: "سوف تتلقى إشعارات عندما يقوم شخص ما بتعيين محادثة لك",
          
          plans: {

            stripe: {
              heading: "إدارة الاشتراك",
              description: "هذا الزر مخصص لإدارة اشتراكك",
              managebtn: "يدير"
            },
            label: "الخطط",
            shopifyPlan: {
              shopifyAccess: "الوصول إلى شوبيفاي",
              shopifyAccessYearly: "خصم 5%",
              perMonth: "كل شهر",
              perYear: "كل سنة",
              features: {
                feature1: "10,000 رصيد chatbot شهريًا",
                feature2: "12,500 رصيد واتس اب / شهر",
                feature3: "11,000,000 حرف/شات بوت",
                feature4: "روابط غير محدودة للتدريب عليها",
                feature5: "الوصول إلى سجلات الدردشة",
                feature6: "التكامل",
                feature7: "الوصول إلى التدخل البشري",
                feature8: "Chatbot المباشر على متجر Shopify الخاص بك",
              },
            },
            webPlan: {
              webAccess: "الوصول إلى الويب",
              webAccessYearly: "خصم 5%",
              perMonth: "كل شهر",
              perYear: "كل سنة",
              upgrade: "يرقي",
              switch_back: "التغيير إلى الخطة الشهرية",
              current_plan: "الخطة الحالية",
              features: {
                feature1: "10,000 رصيد chatbot شهريًا",
                feature2: "12,500 رصيد واتس اب / شهر",
                feature3: "الوصول إلى سجلات الدردشة",
                feature4: "400.000 حرف/شات بوت",
                feature5: "الوصول إلى التدخل البشري",
                feature6: "التكامل",
                feature7: "الوصول إلى ميزة المتابعة",
              },
            },
            credits: {
              chatbot: {
                heading: "أرصدة رسائل chatbot إضافية",
                subHeading: "اعتمادات chatbot",
                per: "لكل",
                modalHeading: "إعادة شحن رصيد الدردشة الآلية",
                modalsubHeading: "الاعتمادات الإضافية التي أريد شراءها:",
                modalPerCharge: "المجموع لكل شحنة",
              },
              whatsapp: {
                heading: "رصيد إضافي لرسائل الواتس اب",
                subHeading: "اعتمادات واتس اب",
                modalHeading: "شحن رصيد الواتس اب",
                modalsubHeading: "رصيد واتساب اضافي اريد شراءه:",
                modalPerCharge: "المجموع لكل شحنة"

              },
              branding: {
                heading: "إزالة 'مدعوم من Jaweb'",
                subHeading: "قم بإزالة العلامة التجارية Jaweb من iframe والقطعة",
                pay: 'ادفع 19 دولارا',
                paid: 'مدفوع',
                paid: "مدفوع"
              },
              upgradeBtn: "يرقي"
            }
          },
          emailSummary: {
            label: "ملخص البريد الإلكتروني",
            heading: "قم بتعيين شعار الشركة لملخص البريد الإلكتروني",
            subHeading: "ملحوظة: الرجاء استخدام صيغة png بخلفية بيضاء",
            uploadImgBtn: "تحميل الصورة"
          },
          transactionHistory: {
            label: "تاريخ المعاملات",
            table: {
              paymentAmt: "مبلغ الدفع (بالدولار الأمريكي)",
              totalCredit: "إجمالي الائتمان",
              creditType: "نوع الائتمان",
              date: "تاريخ",
            }
          }

        },
        botTraining: {
          heading: "قم بتخصيص ذكاء الروبوت الخاص بك إلى الكمال",
          subHeading: "عن طريق تحميل مستندات PDF للتعلم المتخصص أو رابط موقع الويب",
          uploadFile: "تحميل الملفات",
          uploadFileNote: "حدد الملفات التي تريدها وقم بتحميلها",
          fetchLinksBtn: "جلب الروابط",
          pdf: "بي دي إف",
          links: "الروابط",
          source: "مصدر",
          chars: "تم اكتشاف الشخصيات",
          totalChars: "إجمالي الأحرف المكتشفة",
          limit: "حد",
          trainBot: "تدريب روبوت المحادثة",
          chooseFile: "اختر ملفًا أو اسحبه وأفلِته هنا",
          urlLink: "أو قم بلصق الرابط أدناه",
          
          includeLinks: "الروابط المضمنة",
          confirmDltBtn: "تأكيد الحذف",
          yesBtn: "نعم",
          noBtn: "لا",

          confirmDltBtn: "هل أنت متأكد أنك تريد حذف هذا؟",
          confirmDltAll: "تأكيد حذف الكل",
          allLinksDltBtn: "هل أنت متأكد أنك تريد حذف كافة روابط الموقع؟",
          closeBtn: "يغلق"
          

        },
        navbar: {
          customization: "التخصيص",
          Workflow:"سير العمل",
          leads: "العملاء المتوقعة",
          analytics: "التحليلات",
          message: "رسائل",
          integration: "الربط بالمنصات",
          accSetting: "إعدادات حسابك",
          botTraining: "تدريب روبوت جاوب",
          team: "فريقك",
          help: "مساعدة والدعم",
          shopify: "شوبيفي",
          logout: "تسجيل الخروج",
        },
        header: {
          whatsapp: "واتساب",
          chatbot: "روبوت الدردشة",
        }
      }
    },
    es: {
      translation: {
        customization: {
          title: "Interfaz de Chat",
          note: "Nota: Aplica cuando se incrusta en el sitio web",
          initialMsg: "Mensajes Iniciales",
          suggestedMsg: "Mensajes Sugeridos",
          chatbotProfile: "Actualizar foto de perfil del Chatbot",
          theme: "Tema",
          themeNote: "*Si los cambios aquí no se reflejan de inmediato en su sitio web, intente borrar la caché de su navegador o use el modo incógnito. (Los nuevos usuarios verán los cambios de inmediato)*",
          chatbotIcon: "Actualizar Icono del Chatbot",
          disableForm: "Deshabilitar Formulario del Chatbot",
          humanTakeover: "Intervención Humana (Global)",
          save: "Guardar",
          sendMsgPlaceholder: "Enviar Mensaje...",
          emailSummary: "Enviar Resumen del Chat (Email)"
        },
        leads: {
          name: "Nombre",
          date: "Fecha / Hora",
          country: "País",
          phone: "Teléfono",
          action: "Acción",
          exportExcel: "Exportar como Excel"
        },
        analytics: {
          chatbot: {
            label: "Análisis del Chatbot",
            totalChatlog: "Registro Total de Chats",
            uniqueUser: "Usuario Único",
            avgMessagePerSession: "Prom. Mensajes por Sesión",
            userRetention: "Usuario Retenido"
          },
          whatsapp: {
            label: "Análisis de Whatsapp",
            totalChatlog: "Registro Total de Chats",
            uniqueUser: "Usuario Único",
            avgMessagePerSession: "Prom. Mensajes por Sesión",
            userRetention: "Usuario Retenido"
          },
          lineGraph: {
            label: "Total de Usuarios",
            totalChats: "Total de Chats",
            thisYear: "Este Año",
            lastYear: "El Año Pasado"
          },
          barGraph: {
            label: "Satisfacción del Usuario",
            thisYear: "Este Año",
            lastYear: "El Año Pasado"
          },
          piechart: {
            label: "Tasa de Retroalimentación / Resoluciones",
            rating_1: "Resoluciones Confirmadas",
            rating_2: "Resoluciones Supuestas",
            rating_3: "Sin Resoluciones",
            rating_4: "Poco Claro",
            rating_5: "Necesita Mejora"
          },
          areaChart: {
            label: "Conversión de Leads",
            totalOrder: "Total de Pedidos"
          }
        },
        messages: {
          activeChats: "Chats Activos",
          chats: "Chats",
          whatsapp: "Whatsapp",
          takecontrol: "Tomar Control",
          typePlaceholder: "Escriba Aquí y Presione Enter",
          customerInfo: "Información del Cliente",
          contactNo: "Número de Contacto",
          location: "Ubicación",
          email: "Email",
          id: "Id",
          sendChatMsgPlaceholder: "Escriba Aquí y Presione Enter",
          readMore: "... Leer Más",
          readLess: "Leer Menos"
        },
        integration: {
          website: "Sitio Web",
          wordpress: "WordPress",
          whatsapp: "Whatsapp",
          zapier: "Zapier",
          introduction: "Introducción",
          introductionNote: "El innovador plugin de chatbot es la puerta de su empresa a una comunicación fluida y un mejor compromiso con el cliente. Diseñado para revolucionar la forma en que las empresas interactúan con su audiencia, Jaweb trae el poder de la inteligencia artificial a su alcance.",
          introductionSubNote: "Siga los pasos proporcionados para instalar el plugin de Jaweb en su sitio web. 😊 ¡Disfrute!",
          supportedPlateforms: "Plataformas Soportadas",
          websiteNote: "Nota: Esta instalación funciona para archivos HTML puros index.html",
          step1: "Siga los pasos a continuación para instalar el Plugin de Jaweb",
          step2_1: "Primero, cree un ",
          step2_2: "archivo (elija cualquier nombre adecuado para su archivo)",
          step3: "En su etiqueta body, agregue el código a continuación",
          step4: "También agregue el código a continuación en su etiqueta body",
          userIdentification: "Identificación de Usuario",
          step5: "Puede poner esto en la última línea del proyecto",
          css: "CSS",
          step6: "Esto debería ser lo último que coloque en su etiqueta Head",
          wordpressInstall: "Pasos para Instalar en WordPress",
          followSteps: "Siga estos pasos para instalar en WordPress.",
          wstep1: "Haga clic en el botón de abajo para descargar el plugin",
          wstep2: "Vaya a su página de administración de WordPress.",
          wstep3: "Vaya a la sección 'Plugins'.",
          wstep4: "Haga clic en 'Subir Plugin'.",
          wstep5: "Cargue el archivo ZIP descargado (plugin).",
          wstep6: "Active el plugin.",
          wstep7: "Ingrese su nombre de usuario después de la activación.",
          downloadBtn: "Descargar",
          whatsappTab: {
            connectFbTab: "Conectar a Facebook",
            connectBusinessTab: "Conectar a Negocios",
            allSetTab: "Todo Listo",
            integrationNote: "Siga los pasos a continuación para configurar su cuenta de Jaweb. O, también le hemos enviado un correo electrónico con un enlace para que pueda comenzar este proceso en cualquier momento.",
            heading: "Crear Cuenta de Negocios de WhatsApp",
            subHeading1: "Su negocio debe crear una cuenta de negocios de WhatsApp en Facebook, o usar la existente si tiene alguna.",
            subHeading2: "Facebook tiene una política estricta sobre la revisión de la información de su negocio en la cuenta de WhatsApp. Asegúrese de tener la información a continuación preparada antes de continuar.",
            note: "Nota:",
            note1: "El nombre legal y la dirección de su empresa registrados ante las autoridades locales.",
            note2: "El nombre de perfil de WhatsApp debe coincidir con el nombre legal de su empresa.",
            note3: "Un número de teléfono que no tenga una cuenta de WhatsApp existente.",
            fbLogin: "Iniciar sesión con Facebook",
            help: "¿Necesita Ayuda?",
            createNote: "¡Hola! Casi lo tienes, por favor haz clic en el botón de abajo para conectar tu cuenta de negocios de WhatsApp con Jaweb y registrarte como remitente de mensajes de WhatsApp.",
            cancelBtn: "Cancelar",
            profileName: "Nombre del Perfil:",
            profileNamePlaceholder: "Ingresa el nombre del perfil",
            phoneNumber: "Número de Teléfono:",
            phoneNumberPlaceholder: "Ingresa el número de teléfono",
            companyName: "Nombre de la Empresa",
            companyNamePlaceholder: "Ingresa el nombre de la empresa",
            companyEmail: "Correo Electrónico de la Empresa",
            companyEmailPlaceholder: "Ingresa el correo electrónico de la empresa",
            companyWebsite: "Sitio Web de la Empresa",
            companyWebsitePlaceholder: "Ingresa el sitio web de la empresa",
            allSet: "¡Todo Listo, gracias!",
            successNote: "Tu cuenta de Facebook se ha conectado con Jaweb. Dado que es una conversación iniciada por el cliente, tus clientes pueden contactarte a través de WhatsApp. Una vez que recibas un mensaje de un cliente, podrás continuar la conversación."
          }
        },
        teams: {
          email: "Email",
          status: "Estado",
          permission: "Permiso",
          action: "Acción",
          inviteMember: "Invitar Miembro"
        },
        accountSetting: {
          edit: "Editar Perfil",
          billing: "Facturación",
          changePass: "Cambiar Contraseña",
          changePassSubmit:"Entregar",
          notification: "Notificaciones",
          username: "Nombre de Usuario",
          email: "Email",
          firstName: "Nombre",
          lastName: "Apellido",
          region: "Región",
          language: "Idioma",
          saveChange: "Guardar Cambios",
          billing: "Facturación",
          cardType: "Tipo de Tarjeta",
          cardNo: "Número de Tarjeta",
          expire: "Expira en",
          addNew: "Agregar nueva Tarjeta",
          actions: "Acciones",
          buycredit: "Crédito",
          changePass: "Cambiar Contraseña",
          newPass: "Nueva Contraseña",
          confirmPass: "Confirmar Contraseña",
          emailNoti: "Notificaciones por Email",
          emailNotiNote: "Recibirás notificaciones diarias por email.",
          newProductNoti: "Nuevo Producto",
          newProductNotiNote: "Recibirás notificaciones cuando llegue un nuevo producto.",
          chatNoti: "Chat",
          chatNotiNote: "Recibirás notificaciones cuando alguien te asigne un chat.",
          plans: {
            label: "Planes",
            stripe: {
              heading: "Gestionar Suscripción",
              description: "Haga clic en el botón Administrar a continuación para ver o actualizar la configuración de su suscripción. Use esta opción para modificar su plan, actualizar detalles de pago o cancelar su suscripción según sea necesario.",
              managebtn: "Administrar"
            },
            shopifyPlan: {
              shopifyAccess: "Acceso a Shopify",
              shopifyAccessYearly: "5% de descuento",
              perMonth: "Por Mes",
              perYear: "Por Año",
              features: {
                feature1: "10,000 créditos de chatbot/mes",
                feature2: "12,500 créditos de whatsapp/mes",
                feature3: "11,000,000 caracteres/chatbot",
                feature4: "Enlaces ilimitados para entrenar",
                feature5: "Acceso a Chatlogs",
                feature6: "Integraciones",
                feature7: "Acceso a Interferencia Humana",
                feature8: "Chatbot en Vivo en tu Tienda Shopify"
              }
            },
            webPlan: {
              webAccess: "Acceso Web",
              upgrade: "Actualizar",
              switch_back: "Cambiar a Plan Mensual",
              current_plan: "Plan Actual",
              webAccessYearly: "5% de descuento",
              perMonth: "Por Mes",
              perYear: "Por Año",
              features: {
                feature1: "10,000 créditos de chatbot/mes",
                feature2: "12,500 créditos de whatsapp/mes",
                feature3: "Acceso a Chatlogs",
                feature4: "400,000 caracteres/chatbot",
                feature5: "Acceso a Interferencia Humana",
                feature6: "Integraciones",
                feature7: "Acceso a la función de seguimiento"
              }
            },
            credits: {
              chatbot: {
                heading: "Créditos extra para mensajes del chatbot",
                subHeading: "créditos de chatbot",
                per: "Por",
                modalHeading: "Recargar créditos de chatbot",
                modalsubHeading: "Créditos extra que quiero comprar:",
                modalPerCharge: "Total por carga"
              },
              whatsapp: {
                heading: "Créditos extra para mensajes de whatsapp",
                subHeading: "créditos de whatsapp",
                modalHeading: "Recargar créditos de whatsapp",
                modalsubHeading: "Créditos extra de whatsapp que quiero comprar:",
                modalPerCharge: "Total por carga"
              },
              branding: {
                heading: "Eliminar 'Desarrollado por Jaweb'",
                subHeading: "Eliminar la marca Jaweb del iframe y widget",
                pay: "Pagar $19",
                paid: "Pagado"
              },
              upgradeBtn: "Actualizar"
            }
          },
          emailSummary: {
            label: "Resumen por Email",
            heading: "Configurar el logo de la empresa para el resumen por email",
            subHeading: "Nota: Por favor use formato .png con fondo blanco",
            uploadImgBtn: "Subir Imagen"
          },
          transactionHistory: {
            label: "Historial de Transacciones",
            table: {
              paymentAmt: "Monto de Pago (USD)",
              totalCredit: "Crédito Total",
              creditType: "Tipo de Crédito",
              date: "Fecha"
            }
          }
        },
        botTraining: {
          heading: "Ajusta la inteligencia de tu bot a la perfección",
          subHeading: "subiendo documentos PDF para un aprendizaje especializado o un enlace de sitio web",
          uploadFile: "Subir Archivos",
          uploadFileNote: "Seleccione y suba los archivos de su elección",
          fetchLinksBtn: "Obtener Enlaces",
          pdf: "PDF",
          links: "Enlaces",
          source: "Fuente",
          chars: "caracteres detectados",
          totalChars: "Total de caracteres detectados",
          limit: "Límite",
          trainBot: "Entrenar Chatbot",
          chooseFile: "Elija un archivo o arrastre y suelte aquí",
          urlLink: "o pegue la URL a continuación",
          includeLinks: "Enlaces Incluidos",
          confirmDltBtn: "Confirmar Eliminación",
          yesBtn: "Sí",
          noBtn: "No",
          confirmDltBtn: "¿Estás seguro de que deseas eliminar esto?",
          confirmDltAll: "Confirmar Eliminar Todo",
          allLinksDltBtn: "¿Estás seguro de que deseas eliminar todos los enlaces del sitio web?",
          closeBtn: "Cerrar"
        },
        navbar: {
          customization: "Personalización",
          leads: "Leads",
          analytics: "Análisis",
          message: "Mensaje",
          integration: "Integración",
          accSetting: "Configuración de Cuenta",
          botTraining: "Entrenamiento del Bot",
          shopify: "Shopify",
          team: "Equipo",
          help: "Ayuda",
          logout: "Cerrar Sesión"
        },
        header: {
          whatsapp: "Whatsapp",
          chatbot: "Chatbot"
        }
      }
    },
    fr: {
      translation : {
  customization: {
    title: "Interface de Chat",
    note: "Remarque : S'applique lors de l'intégration sur le site web",
    initialMsg: "Messages Initiaux",
    suggestedMsg: "Messages Suggérés",
    chatbotProfile: "Mettre à jour la photo de profil du Chatbot",
    theme: "Thème",
    themeNote: "*Si les changements ici ne se reflètent pas immédiatement sur votre site web, essayez de vider le cache de votre navigateur ou d'utiliser le mode incognito. (Les nouveaux utilisateurs verront les changements immédiatement)*",
    chatbotIcon: "Mettre à jour l'icône du Chatbot",
    disableForm: "Désactiver le formulaire du Chatbot",
    humanTakeover: "Intervention Humaine (Globale)",
    save: "Sauvegarder",
    sendMsgPlaceholder: "Envoyer un message...",
    emailSummary: "Envoyer le résumé du Chat (Email)"
  },
  leads: {
    name: "Nom",
    date: "Date / Heure",
    country: "Pays",
    phone: "Téléphone",
    action: "Action",
    exportExcel: "Exporter en tant qu'Excel"
  },
  analytics: {
    chatbot: {
      label: "Analyse du Chatbot",
      totalChatlog: "Journal Total des Chats",
      uniqueUser: "Utilisateur Unique",
      avgMessagePerSession: "Moy. Messages par Session",
      userRetention: "Utilisateur Retenu"
    },
    whatsapp: {
      label: "Analyse de Whatsapp",
      totalChatlog: "Journal Total des Chats",
      uniqueUser: "Utilisateur Unique",
      avgMessagePerSession: "Moy. Messages par Session",
      userRetention: "Utilisateur Retenu"
    },
    lineGraph: {
      label: "Total des Utilisateurs",
      totalChats: "Total des Chats",
      thisYear: "Cette Année",
      lastYear: "L'Année Dernière"
    },
    barGraph: {
      label: "Satisfaction de l'Utilisateur",
      thisYear: "Cette Année",
      lastYear: "L'Année Dernière"
    },
    piechart: {
      label: "Taux de Retours / Résolutions",
      rating_1: "Résolutions Confirmées",
      rating_2: "Résolutions Supposées",
      rating_3: "Sans Résolutions",
      rating_4: "Peu Clair",
      rating_5: "Nécessite Amélioration"
    },
    areaChart: {
      label: "Conversion de Leads",
      totalOrder: "Total des Commandes"
    }
  },
  messages: {
    activeChats: "Chats Actifs",
    chats: "Chats",
    whatsapp: "Whatsapp",
    takecontrol: "Prendre le Contrôle",
    typePlaceholder: "Écrivez ici et appuyez sur Entrée",
    customerInfo: "Informations sur le Client",
    contactNo: "Numéro de Contact",
    location: "Emplacement",
    email: "Email",
    id: "Id",
    sendChatMsgPlaceholder: "Écrivez ici et appuyez sur Entrée",
    readMore: "... Lire Plus",
    readLess: "Lire Moins"
  },
  integration: {
    website: "Site Web",
    wordpress: "WordPress",
    whatsapp: "Whatsapp",
    zapier: "Zapier",
    introduction: "Introduction",
    introductionNote: "Le plugin innovant de chatbot est la porte de votre entreprise à une communication fluide et un meilleur engagement client. Conçu pour révolutionner la façon dont les entreprises interagissent avec leur audience, Jaweb apporte le pouvoir de l'intelligence artificielle à portée de main.",
    introductionSubNote: "Suivez les étapes fournies pour installer le plugin Jaweb sur votre site web. 😊 Profitez-en !",
    supportedPlateforms: "Plateformes Supportées",
    websiteNote: "Remarque : Cette installation fonctionne pour les fichiers HTML purs index.html",
    step1: "Suivez les étapes ci-dessous pour installer le Plugin Jaweb",
    step2_1: "Tout d'abord, créez un ",
    step2_2: "fichier (choisissez un nom approprié pour votre fichier)",
    step3: "Dans votre balise body, ajoutez le code ci-dessous",
    step4: "Ajoutez également le code ci-dessous dans votre balise body",
    userIdentification: "Identification de l'Utilisateur",
    step5: "Vous pouvez mettre cela dans la dernière ligne du projet",
    css: "CSS",
    step6: "Cela devrait être la dernière chose que vous mettez dans votre balise Head",
    wordpressInstall: "Étapes pour Installer sur WordPress",
    followSteps: "Suivez ces étapes pour installer sur WordPress.",
    wstep1: "Cliquez sur le bouton ci-dessous pour télécharger le plugin",
    wstep2: "Allez sur votre page d'administration WordPress.",
    wstep3: "Allez dans la section 'Plugins'.",
    wstep4: "Cliquez sur 'Téléverser Plugin'.",
    wstep5: "Téléversez le fichier ZIP téléchargé (plugin).",
    wstep6: "Activez le plugin.",
    wstep7: "Entrez votre nom d'utilisateur après l'activation.",
    downloadBtn: "Télécharger",
    whatsappTab: {
      connectFbTab: "Connecter à Facebook",
      connectBusinessTab: "Connecter à Entreprise",
      allSetTab: "Tout Prêt",
      integrationNote: "Suivez les étapes ci-dessous pour configurer votre compte Jaweb. Ou, nous vous avons également envoyé un email avec un lien pour que vous puissiez commencer ce processus à tout moment.",
      heading: "Créer un Compte Entreprise de WhatsApp",
      subHeading1: "Votre entreprise doit créer un compte entreprise de WhatsApp sur Facebook, ou utiliser l'existant si vous en avez un.",
      subHeading2: "Facebook a une politique stricte concernant la vérification des informations de votre entreprise sur le compte WhatsApp. Assurez-vous d'avoir les informations ci-dessous prêtes avant de continuer.",
      note: "Remarque :",
      note1: "Le nom légal et l'adresse de votre entreprise enregistrés auprès des autorités locales.",
      note2: "Le nom de profil WhatsApp doit correspondre au nom légal de votre entreprise.",
      note3: "Un numéro de téléphone qui n'a pas de compte WhatsApp existant.",
      fbLogin: "Se connecter avec Facebook",
      help: "Besoin d'Aide ?",
      createNote: "Bonjour ! Vous y êtes presque, veuillez cliquer sur le bouton ci-dessous pour connecter votre compte entreprise de WhatsApp avec Jaweb et vous inscrire en tant qu'expéditeur de messages WhatsApp.",
      cancelBtn: "Annuler",
      profileName: "Nom du Profil :",
      profileNamePlaceholder: "Entrez le nom du profil",
      phoneNumber: "Numéro de Téléphone :",
      phoneNumberPlaceholder: "Entrez le numéro de téléphone",
      companyName: "Nom de l'Entreprise",
      companyNamePlaceholder: "Entrez le nom de l'entreprise",
      companyEmail: "Email de l'Entreprise",
      companyEmailPlaceholder: "Entrez l'email de l'entreprise",
      companyWebsite: "Site Web de l'Entreprise",
      companyWebsitePlaceholder: "Entrez le site web de l'entreprise",
      allSet: "Tout Prêt, merci !",
      successNote: "Votre compte Facebook a été connecté à Jaweb. Étant donné que c'est une conversation initiée par le client, vos clients peuvent vous contacter via WhatsApp. Une fois que vous recevez un message d'un client, vous pourrez continuer la conversation."
    }
  },
  teams: {
    email: "Email",
    status: "État",
    permission: "Permission",
    action: "Action",
    inviteMember: "Inviter un Membre"
  },
  accountSetting: {
    edit: "Modifier le Profil",
    billing: "Facturation",
    changePass: "Changer le Mot de Passe",
    changePassSubmit:"Soumettre",
    notification: "Notifications",
    username: "Nom d'Utilisateur",
    email: "Email",
    firstName: "Prénom",
    lastName: "Nom",
    region: "Région",
    language: "Langue",
    saveChange: "Sauvegarder les Changements",
    billing: "Facturation",
    cardType: "Type de Carte",
    cardNo: "Numéro de Carte",
    expire: "Expire le",
    addNew: "Ajouter une Nouvelle Carte",
    actions: "Actions",
    buycredit: "Crédit",
    changePass: "Changer le Mot de Passe",
    newPass: "Nouveau Mot de Passe",
    confirmPass: "Confirmer le Mot de Passe",
    emailNoti: "Notifications par Email",
    emailNotiNote: "Vous recevrez des notifications quotidiennes par email.",
    newProductNoti: "Nouveau Produit",
    newProductNotiNote: "Vous recevrez des notifications lorsque de nouveaux produits arriveront.",
    chatNoti: "Chat",
    chatNotiNote: "Vous recevrez des notifications lorsque quelqu'un vous attribue un chat.",
    plans: {
      label: "Plans",
      stripe: {
        heading: "Gérer l'Abonnement",
        description: "Cliquez sur le bouton Gérer ci-dessous pour voir ou mettre à jour les paramètres de votre abonnement. Utilisez cette option pour modifier votre plan, mettre à jour les détails de paiement ou annuler votre abonnement si nécessaire.",
        managebtn: "Gérer"
      },
      shopifyPlan: {
        shopifyAccess: "Accès à Shopify",
        shopifyAccessYearly: "5% de réduction",
        perMonth: "Par Mois",
        perYear: "Par An",
        features: {
          feature1: "10,000 crédits de chatbot/mois",
          feature2: "12,500 crédits de whatsapp/mois",
          feature3: "11,000,000 caractères/chatbot",
          feature4: "Liens illimités pour l'entraînement",
          feature5: "Accès aux Chatlogs",
          feature6: "Intégrations",
          feature7: "Accès à l'Intervention Humaine",
          feature8: "Chatbot en Direct dans votre Boutique Shopify"
        }
      },
      webPlan: {
        webAccess: "Accès Web",
        upgrade: "Mettre à Jour",
        switch_back: "Changer vers le Plan Mensuel",
        current_plan: "Plan Actuel",
        webAccessYearly: "5% de réduction",
        perMonth: "Par Mois",
        perYear: "Par An",
        features: {
          feature1: "10,000 crédits de chatbot/mois",
          feature2: "12,500 crédits de whatsapp/mois",
          feature3: "Accès aux Chatlogs",
          feature4: "400,000 caractères/chatbot",
          feature5: "Accès à l'Intervention Humaine",
          feature6: "Intégrations",
          feature7: "Accès à la fonction de suivi"
        }
      },
      credits: {
        chatbot: {
          heading: "Crédits supplémentaires pour les messages du chatbot",
          subHeading: "crédits de chatbot",
          per: "Par",
          modalHeading: "Recharger des crédits de chatbot",
          modalsubHeading: "Crédits supplémentaires que je souhaite acheter :",
          modalPerCharge: "Total par charge"
        },
        whatsapp: {
          heading: "Crédits supplémentaires pour les messages de whatsapp",
          subHeading: "crédits de whatsapp",
          modalHeading: "Recharger des crédits de whatsapp",
          modalsubHeading: "Crédits supplémentaires de whatsapp que je souhaite acheter :",
          modalPerCharge: "Total par charge"
        },
        branding: {
          heading: "Supprimer 'Développé par Jaweb'",
          subHeading: "Supprimer la marque Jaweb du iframe et du widget",
          pay: "Payer $19",
          paid: "Payé"
        },
        upgradeBtn: "Mettre à Jour"
      }
    },
    emailSummary: {
      label: "Résumé par Email",
      heading: "Configurer le logo de l'entreprise pour le résumé par email",
      subHeading: "Remarque : Veuillez utiliser le format .png avec un fond blanc",
      uploadImgBtn: "Télécharger l'Image"
    },
    transactionHistory: {
      label: "Historique des Transactions",
      table: {
        paymentAmt: "Montant du Paiement (USD)",
        totalCredit: "Crédit Total",
        creditType: "Type de Crédit",
        date: "Date"
      }
    }
  },
  botTraining: {
    heading: "Ajustez l'intelligence de votre bot à la perfection",
    subHeading: "téléchargez des documents PDF pour un apprentissage spécialisé ou un lien de site web",
    uploadFile: "Télécharger des Fichiers",
    uploadFileNote: "Sélectionnez et téléchargez les fichiers de votre choix",
    fetchLinksBtn: "Obtenir des Liens",
    pdf: "PDF",
    links: "Liens",
    source: "Source",
    chars: "caractères détectés",
    totalChars: "Total de caractères détectés",
    limit: "Limite",
    trainBot: "Entraîner le Chatbot",
    chooseFile: "Choisissez un fichier ou faites-le glisser ici",
    urlLink: "ou collez l'URL ci-dessous",
    includeLinks: "Liens Inclus",
    confirmDltBtn: "Confirmer la Suppression",
    yesBtn: "Oui",
    noBtn: "Non",
    confirmDltBtn: "Êtes-vous sûr de vouloir supprimer cela ?",
    confirmDltAll: "Confirmer la Suppression de Tout",
    allLinksDltBtn: "Êtes-vous sûr de vouloir supprimer tous les liens du site web ?",
    closeBtn: "Fermer"
  },
  navbar: {
    customization: "Personnalisation",
    leads: "Leads",
    analytics: "Analyse",
    message: "Message",
    integration: "Intégration",
    accSetting: "Paramètres du Compte",
    botTraining: "Entraînement du Bot",
    shopify: "Shopify",
    team: "Équipe",
    help: "Aide",
    logout: "Se Déconnecter"
  },
  header: {
    whatsapp: "Whatsapp",
    chatbot: "Chatbot"
  }
}
    },
    de: {
       translation : {
  customization: {
    title: "Chat-Interface",
    note: "Hinweis: Gilt bei Integration auf der Webseite",
    initialMsg: "Anfangsnachrichten",
    suggestedMsg: "Vorgeschlagene Nachrichten",
    chatbotProfile: "Profilbild des Chatbots aktualisieren",
    theme: "Thema",
    themeNote: "*Wenn die Änderungen hier nicht sofort auf Ihrer Webseite angezeigt werden, versuchen Sie, den Cache Ihres Browsers zu leeren oder den Inkognito-Modus zu verwenden. (Neue Benutzer sehen die Änderungen sofort)*",
    chatbotIcon: "Chatbot-Icon aktualisieren",
    disableForm: "Chatbot-Formular deaktivieren",
    humanTakeover: "Menschliches Eingreifen (global)",
    save: "Speichern",
    sendMsgPlaceholder: "Nachricht senden...",
    emailSummary: "Chat-Zusammenfassung senden (E-Mail)"
  },
  leads: {
    name: "Name",
    date: "Datum / Uhrzeit",
    country: "Land",
    phone: "Telefon",
    action: "Aktion",
    exportExcel: "Als Excel exportieren"
  },
  analytics: {
    chatbot: {
      label: "Chatbot-Analyse",
      totalChatlog: "Gesamt-Chatprotokoll",
      uniqueUser: "Eindeutiger Benutzer",
      avgMessagePerSession: "Durchschn. Nachrichten pro Sitzung",
      userRetention: "Benutzerbindung"
    },
    whatsapp: {
      label: "WhatsApp-Analyse",
      totalChatlog: "Gesamt-Chatprotokoll",
      uniqueUser: "Eindeutiger Benutzer",
      avgMessagePerSession: "Durchschn. Nachrichten pro Sitzung",
      userRetention: "Benutzerbindung"
    },
    lineGraph: {
      label: "Gesamtbenutzer",
      totalChats: "Gesamt-Chats",
      thisYear: "Dieses Jahr",
      lastYear: "Letztes Jahr"
    },
    barGraph: {
      label: "Benutzersatisfaction",
      thisYear: "Dieses Jahr",
      lastYear: "Letztes Jahr"
    },
    piechart: {
      label: "Rücklauf-/Lösungsquote",
      rating_1: "Bestätigte Lösungen",
      rating_2: "Vermutete Lösungen",
      rating_3: "Keine Lösungen",
      rating_4: "Unklar",
      rating_5: "Verbesserungsbedürftig"
    },
    areaChart: {
      label: "Lead-Konversion",
      totalOrder: "Gesamtbestellungen"
    }
  },
  messages: {
    activeChats: "Aktive Chats",
    chats: "Chats",
    whatsapp: "WhatsApp",
    takecontrol: "Kontrolle übernehmen",
    typePlaceholder: "Hier eingeben und Enter drücken",
    customerInfo: "Kundeninformationen",
    contactNo: "Kontakt-Nr.",
    location: "Standort",
    email: "E-Mail",
    id: "ID",
    sendChatMsgPlaceholder: "Hier eingeben und Enter drücken",
    readMore: "... Mehr lesen",
    readLess: "Weniger lesen"
  },
  integration: {
    website: "Webseite",
    wordpress: "WordPress",
    whatsapp: "WhatsApp",
    zapier: "Zapier",
    introduction: "Einführung",
    introductionNote: "Das innovative Chatbot-Plugin ist das Tor zu einer nahtlosen Kommunikation und besserem Kundenengagement für Ihr Unternehmen. Entwickelt, um die Art und Weise zu revolutionieren, wie Unternehmen mit ihrem Publikum interagieren, bringt Jaweb die Macht der künstlichen Intelligenz in Ihre Reichweite.",
    introductionSubNote: "Befolgen Sie die bereitgestellten Schritte, um das Jaweb-Plugin auf Ihrer Webseite zu installieren. 😊 Viel Spaß!",
    supportedPlateforms: "Unterstützte Plattformen",
    websiteNote: "Hinweis: Diese Installation funktioniert für reine HTML-Dateien index.html",
    step1: "Befolgen Sie die Schritte unten, um das Jaweb-Plugin zu installieren",
    step2_1: "Zuerst erstellen Sie eine ",
    step2_2: "Datei (wählen Sie einen geeigneten Namen für Ihre Datei)",
    step3: "Fügen Sie den folgenden Code in Ihr Body-Tag ein",
    step4: "Fügen Sie auch den folgenden Code in Ihr Body-Tag ein",
    userIdentification: "Benutzeridentifikation",
    step5: "Sie können dies in die letzte Zeile des Projekts setzen",
    css: "CSS",
    step6: "Das sollte das Letzte sein, was Sie in Ihr Head-Tag einfügen",
    wordpressInstall: "Schritte zur Installation auf WordPress",
    followSteps: "Befolgen Sie diese Schritte zur Installation auf WordPress.",
    wstep1: "Klicken Sie auf die Schaltfläche unten, um das Plugin herunterzuladen",
    wstep2: "Gehen Sie zu Ihrem WordPress-Adminbereich.",
    wstep3: "Gehen Sie zum Abschnitt 'Plugins'.",
    wstep4: "Klicken Sie auf 'Plugin hochladen'.",
    wstep5: "Laden Sie die heruntergeladene ZIP-Datei (Plugin) hoch.",
    wstep6: "Aktivieren Sie das Plugin.",
    wstep7: "Geben Sie Ihren Benutzernamen nach der Aktivierung ein.",
    downloadBtn: "Herunterladen",
    whatsappTab: {
      connectFbTab: "Mit Facebook verbinden",
      connectBusinessTab: "Mit Unternehmen verbinden",
      allSetTab: "Alles bereit",
      integrationNote: "Befolgen Sie die Schritte unten, um Ihr Jaweb-Konto einzurichten. Oder wir haben Ihnen auch eine E-Mail mit einem Link geschickt, damit Sie diesen Prozess jederzeit beginnen können.",
      heading: "WhatsApp Geschäftskonto erstellen",
      subHeading1: "Ihr Unternehmen muss ein WhatsApp Geschäftskonto bei Facebook erstellen oder das bestehende verwenden, wenn Sie eines haben.",
      subHeading2: "Facebook hat eine strenge Richtlinie zur Überprüfung der Informationen Ihres Unternehmens im WhatsApp-Konto. Stellen Sie sicher, dass Sie die folgenden Informationen bereit haben, bevor Sie fortfahren.",
      note: "Hinweis:",
      note1: "Der rechtliche Name und die Adresse Ihres Unternehmens, die bei den örtlichen Behörden registriert sind.",
      note2: "Der WhatsApp-Profilname muss mit dem rechtlichen Namen Ihres Unternehmens übereinstimmen.",
      note3: "Eine Telefonnummer, die kein bestehendes WhatsApp-Konto hat.",
      fbLogin: "Mit Facebook anmelden",
      help: "Hilfe benötigt?",
      createNote: "Hallo! Sie sind fast da, bitte klicken Sie auf die Schaltfläche unten, um Ihr WhatsApp Geschäftskonto mit Jaweb zu verbinden und sich als WhatsApp-Nachrichtensender zu registrieren.",
      cancelBtn: "Abbrechen",
      profileName: "Profilname:",
      profileNamePlaceholder: "Profilnamen eingeben",
      phoneNumber: "Telefonnummer:",
      phoneNumberPlaceholder: "Telefonnummer eingeben",
      companyName: "Unternehmensname",
      companyNamePlaceholder: "Unternehmensnamen eingeben",
      companyEmail: "Unternehmens-E-Mail",
      companyEmailPlaceholder: "Unternehmens-E-Mail eingeben",
      companyWebsite: "Unternehmenswebsite",
      companyWebsitePlaceholder: "Unternehmenswebsite eingeben",
      allSet: "Alles bereit, danke!",
      successNote: "Ihr Facebook-Konto wurde mit Jaweb verbunden. Da dies eine vom Kunden initiierte Konversation ist, können Ihre Kunden Sie über WhatsApp kontaktieren. Sobald Sie eine Nachricht von einem Kunden erhalten, können Sie das Gespräch fortsetzen."
    }
  },
  teams: {
    email: "E-Mail",
    status: "Status",
    permission: "Berechtigung",
    action: "Aktion",
    inviteMember: "Mitglied einladen"
  },
  accountSetting: {
    edit: "Profil bearbeiten",
    billing: "Abrechnung",
    changePass: "Passwort ändern",
    changePassSubmit:"Einreichen",
    notification: "Benachrichtigungen",
    username: "Benutzername",
    email: "E-Mail",
    firstName: "Vorname",
    lastName: "Nachname",
    region: "Region",
    language: "Sprache",
    saveChange: "Änderungen speichern",
    billing: "Abrechnung",
    cardType: "Kartentyp",
    cardNo: "Kartennummer",
    expire: "Läuft ab am",
    addNew: "Neue Karte hinzufügen",
    actions: "Aktionen",
    buycredit: "Guthaben",
    changePass: "Passwort ändern",
    newPass: "Neues Passwort",
    confirmPass: "Passwort bestätigen",
    emailNoti: "E-Mail-Benachrichtigungen",
    emailNotiNote: "Sie erhalten tägliche Benachrichtigungen per E-Mail.",
    newProductNoti: "Neues Produkt",
    newProductNotiNote: "Sie erhalten Benachrichtigungen, wenn neue Produkte verfügbar sind.",
    chatNoti: "Chat",
    chatNotiNote: "Sie erhalten Benachrichtigungen, wenn Ihnen jemand einen Chat zuweist.",
    plans: {
      label: "Pläne",
      stripe: {
        heading: "Abonnement verwalten",
        description: "Klicken Sie auf die Schaltfläche Verwalten unten, um die Einstellungen Ihres Abonnements anzuzeigen oder zu aktualisieren. Verwenden Sie diese Seite, um auch Ihre Zahlungsmethoden zu aktualisieren oder Ihr Abonnement zu kündigen, wenn dies erforderlich ist.",
        managebtn: "Verwalten"
      },
      shopifyPlan: {
        shopifyAccess: "Zugang zu Shopify",
        shopifyAccessYearly: "5% Rabatt",
        perMonth: "Pro Monat",
        perYear: "Pro Jahr",
        features: {
          feature1: "10.000 Chatbot-Credits/Monat",
          feature2: "12.500 WhatsApp-Credits/Monat",
          feature3: "11.000.000 Zeichen/Chatbot",
          feature4: "Unbegrenzte Links zum Training",
          feature5: "Zugang zu Chatlogs",
          feature6: "Integrationen",
          feature7: "Zugang zu menschlichem Eingreifen",
          feature8: "Live-Chatbot in Ihrem Shopify-Shop"
        }
      },
      webPlan: {
        webAccess: "Web-Zugang",
        upgrade: "Upgrade",
        switch_back: "Zur monatlichen Plan wechseln",
        current_plan: "Aktueller Plan",
        webAccessYearly: "5% Rabatt",
        perMonth: "Pro Monat",
        perYear: "Pro Jahr",
        features: {
          feature1: "10.000 Chatbot-Credits/Monat",
          feature2: "12.500 WhatsApp-Credits/Monat",
          feature3: "Zugang zu Chatlogs",
          feature4: "400.000 Zeichen/Chatbot",
          feature5: "Zugang zu menschlichem Eingreifen",
          feature6: "Integrationen",
          feature7: "Zugang zur Nachverfolgungsfunktion"
        }
      },
      credits: {
        chatbot: {
          heading: "Zusätzliche Credits für Chatbot-Nachrichten",
          subHeading: "Chatbot-Credits",
          per: "Pro",
          modalHeading: "Chatbot-Credits aufladen",
          modalsubHeading: "Zusätzliche Credits, die ich kaufen möchte:",
          modalPerCharge: "Gesamt pro Aufladung"
        },
        whatsapp: {
          heading: "Zusätzliche Credits für WhatsApp-Nachrichten",
          subHeading: "WhatsApp-Credits",
          modalHeading: "WhatsApp-Credits aufladen",
          modalsubHeading: "Zusätzliche WhatsApp-Credits, die ich kaufen möchte:",
          modalPerCharge: "Gesamt pro Aufladung"
        },
        branding: {
          heading: "Entfernen Sie 'Entwickelt von Jaweb'",
          subHeading: "Entfernen Sie das Jaweb-Branding aus dem iFrame und dem Widget",
          pay: "Zahlen Sie $19",
          paid: "Bezahlt"
        },
        upgradeBtn: "Upgrade"
      }
    },
    emailSummary: {
      label: "E-Mail-Zusammenfassung",
      heading: "Unternehmenslogo für E-Mail-Zusammenfassung einrichten",
      subHeading: "Hinweis: Bitte verwenden Sie das .png-Format mit einem weißen Hintergrund",
      uploadImgBtn: "Bild hochladen"
    },
    transactionHistory: {
      label: "Transaktionsverlauf",
      table: {
        paymentAmt: "Zahlungsbetrag (USD)",
        totalCredit: "Gesamtguthaben",
        creditType: "Guthabenart",
        date: "Datum"
      }
    }
  },
  botTraining: {
    heading: "Optimieren Sie die Intelligenz Ihres Bots",
    subHeading: "Laden Sie PDF-Dokumente für spezialisiertes Lernen oder einen Webseiten-Link hoch",
    uploadFile: "Dateien hochladen",
    uploadFileNote: "Wählen Sie die gewünschten Dateien aus und laden Sie sie hoch",
    fetchLinksBtn: "Links abrufen",
    pdf: "PDF",
    links: "Links",
    source: "Quelle",
    chars: "erkannt Zeichen",
    totalChars: "Gesamtanzahl der erkannten Zeichen",
    limit: "Limit",
    trainBot: "Chatbot trainieren",
    chooseFile: "Wählen Sie eine Datei aus oder ziehen Sie sie hierher",
    urlLink: "oder fügen Sie die URL unten ein",
    includeLinks: "Eingeschlossene Links",
    confirmDltBtn: "Löschung bestätigen",
    yesBtn: "Ja",
    noBtn: "Nein",
    confirmDltBtn: "Sind Sie sicher, dass Sie das löschen möchten?",
    confirmDltAll: "Bestätigen Sie die Löschung aller",
    allLinksDltBtn: "Sind Sie sicher, dass Sie alle Links der Webseite löschen möchten?",
    closeBtn: "Schließen"
  },
  navbar: {
    customization: "Anpassung",
    leads: "Leads",
    analytics: "Analyse",
    message: "Nachricht",
    integration: "Integration",
    accSetting: "Kontoeinstellungen",
    botTraining: "Bot-Training",
    shopify: "Shopify",
    team: "Team",
    help: "Hilfe",
    logout: "Abmelden"
  },
  header: {
    whatsapp: "WhatsApp",
    chatbot: "Chatbot"
  }
}

    },
    it: {
      translation : {
  customization: {
    title: "Interfaccia Chat",
    note: "Nota: Valido per integrazione nel sito web",
    initialMsg: "Messaggi iniziali",
    suggestedMsg: "Messaggi suggeriti",
    chatbotProfile: "Aggiorna l'immagine del profilo del chatbot",
    theme: "Tema",
    themeNote: "*Se le modifiche qui non vengono visualizzate immediatamente sul tuo sito web, prova a svuotare la cache del tuo browser o ad usare la modalità in incognito. (I nuovi utenti vedranno le modifiche immediatamente)*",
    chatbotIcon: "Aggiorna l'icona del chatbot",
    disableForm: "Disabilita il modulo del chatbot",
    humanTakeover: "Intervento umano (globale)",
    save: "Salva",
    sendMsgPlaceholder: "Invia un messaggio...",
    emailSummary: "Invia un riepilogo della chat (email)"
  },
  leads: {
    name: "Nome",
    date: "Data/Ora",
    country: "Paese",
    phone: "Telefono",
    action: "Azione",
    exportExcel: "Esporta in Excel"
  },
  analytics: {
    chatbot: {
      label: "Analisi del Chatbot",
      totalChatlog: "Registro chat totale",
      uniqueUser: "Utente unico",
      avgMessagePerSession: "Messaggi medi per sessione",
      userRetention: "Retention degli utenti"
    },
    whatsapp: {
      label: "Analisi WhatsApp",
      totalChatlog: "Registro chat totale",
      uniqueUser: "Utente unico",
      avgMessagePerSession: "Messaggi medi per sessione",
      userRetention: "Retention degli utenti"
    },
    lineGraph: {
      label: "Utenti totali",
      totalChats: "Chat totali",
      thisYear: "Quest'anno",
      lastYear: "Anno scorso"
    },
    barGraph: {
      label: "Soddisfazione degli utenti",
      thisYear: "Quest'anno",
      lastYear: "Anno scorso"
    },
    piechart: {
      label: "Tasso di risposta/risoluzione",
      rating_1: "Soluzioni confermate",
      rating_2: "Soluzioni presunte",
      rating_3: "Nessuna soluzione",
      rating_4: "Non chiaro",
      rating_5: "Migliorabile"
    },
    areaChart: {
      label: "Conversione lead",
      totalOrder: "Ordini totali"
    }
  },
  messages: {
    activeChats: "Chat attive",
    chats: "Chat",
    whatsapp: "WhatsApp",
    takecontrol: "Prendere controllo",
    typePlaceholder: "Scrivi qui e premi invio",
    customerInfo: "Informazioni sul cliente",
    contactNo: "Numero di contatto",
    location: "Posizione",
    email: "Email",
    id: "ID",
    sendChatMsgPlaceholder: "Scrivi qui e premi invio",
    readMore: "... Leggi di più",
    readLess: "Leggi meno"
  },
  integration: {
    website: "Sito web",
    wordpress: "WordPress",
    whatsapp: "WhatsApp",
    zapier: "Zapier",
    introduction: "Introduzione",
    introductionNote: "Il plugin chatbot innovativo è la porta per una comunicazione senza interruzioni e un migliore coinvolgimento dei clienti per la tua azienda. Sviluppato per rivoluzionare il modo in cui le aziende interagiscono con il loro pubblico, Jaweb porta il potere dell'intelligenza artificiale a portata di mano.",
    introductionSubNote: "Segui i passaggi forniti per installare il plugin Jaweb sul tuo sito web. 😊 Buon divertimento!",
    supportedPlateforms: "Piattaforme supportate",
    websiteNote: "Nota: Questa installazione funziona per file HTML puri index.html",
    step1: "Segui i passaggi qui sotto per installare il plugin Jaweb",
    step2_1: "Per prima cosa, crea un ",
    step2_2: "file (scegli un nome adatto per il tuo file)",
    step3: "Inserisci il seguente codice nel tuo tag Body",
    step4: "Inserisci anche il seguente codice nel tuo tag Body",
    userIdentification: "Identificazione dell'utente",
    step5: "Puoi mettere questo nell'ultima riga del progetto",
    css: "CSS",
    step6: "Questo dovrebbe essere l'ultimo che inserisci nel tuo tag Head",
    wordpressInstall: "Passaggi per l'installazione su WordPress",
    followSteps: "Segui questi passaggi per l'installazione su WordPress.",
    wstep1: "Clicca il pulsante qui sotto per scaricare il plugin",
    wstep2: "Vai alla tua area admin di WordPress.",
    wstep3: "Vai alla sezione 'Plugin'.",
    wstep4: "Clicca su 'Carica plugin'.",
    wstep5: "Carica il file ZIP scaricato (plugin).",
    wstep6: "Attiva il plugin.",
    wstep7: "Inserisci il tuo nome utente dopo l'attivazione.",
    downloadBtn: "Scarica",
    whatsappTab: {
      connectFbTab: "Collegati a Facebook",
      connectBusinessTab: "Collegati all'azienda",
      allSetTab: "Tutto pronto",
      integrationNote: "Segui i passaggi qui sotto per configurare il tuo account Jaweb. Oppure ti abbiamo inviato un'email con un link per iniziare questo processo in qualsiasi momento.",
      heading: "Crea un account aziendale WhatsApp",
      subHeading1: "La tua azienda deve creare un account aziendale WhatsApp su Facebook o utilizzare quello esistente, se ne hai uno.",
      subHeading2: "Facebook ha una politica rigorosa per la verifica delle informazioni della tua azienda nell'account WhatsApp. Assicurati di avere le seguenti informazioni pronte prima di procedere.",
      note: "Nota:",
      note1: "Il nome legale e l'indirizzo della tua azienda, registrati presso le autorità locali.",
      note2: "Il nome del profilo WhatsApp deve corrispondere al nome legale della tua azienda.",
      note3: "Un numero di telefono che non ha un account WhatsApp esistente.",
      fbLogin: "Accedi con Facebook",
      help: "Hai bisogno di aiuto?",
      createNote: "Ciao! Sei quasi arrivato, per favore clicca il pulsante qui sotto per collegare il tuo account aziendale WhatsApp a Jaweb e registrarti come mittente di messaggi WhatsApp.",
      cancelBtn: "Annulla",
      profileName: "Nome profilo:",
      profileNamePlaceholder: "Inserisci nome profilo",
      phoneNumber: "Numero di telefono:",
      phoneNumberPlaceholder: "Inserisci numero di telefono",
      companyName: "Nome dell'azienda",
      companyNamePlaceholder: "Inserisci nome azienda",
      companyEmail: "Email aziendale",
      companyEmailPlaceholder: "Inserisci email aziendale",
      companyWebsite: "Sito web aziendale",
      companyWebsitePlaceholder: "Inserisci sito web aziendale",
      allSet: "Tutto pronto, grazie!",
      successNote: "Il tuo account Facebook è stato collegato a Jaweb. Poiché questa è una conversazione iniziata dal cliente, i tuoi clienti possono contattarti tramite WhatsApp. Una volta ricevuto un messaggio da un cliente, puoi continuare la conversazione."
    }
  },
  teams: {
    email: "Email",
    status: "Stato",
    permission: "Permesso",
    action: "Azione",
    inviteMember: "Invita membro"
  },
  accountSetting: {
    edit: "Modifica profilo",
    billing: "Fatturazione",
    changePass: "Cambia password",
    changePassSubmit:"Somm",
    notification: "Notifiche",
    username: "Nome utente",
    email: "Email",
    firstName: "Nome",
    lastName: "Cognome",
    region: "Regione",
    language: "Lingua",
    saveChange: "Salva modifiche",
    billing: "Fatturazione",
    cardType: "Tipo di carta",
    cardNo: "Numero di carta",
    expire: "Scade il",
    addNew: "Aggiungi nuova carta",
    actions: "Azioni",
    buycredit: "Acquista credito",
    changePass: "Cambia password",
    newPass: "Nuova password",
    confirmPass: "Conferma password",
    emailNoti: "Notifiche email",
    emailNotiNote: "Riceverai notifiche email giornaliere.",
    newProductNoti: "Nuovo prodotto",
    newProductNotiNote: "Riceverai notifiche quando nuovi prodotti saranno disponibili.",
    chatNoti: "Chat",
    chatNotiNote: "Riceverai notifiche quando qualcuno ti assegna una chat.",
    plans: {
      label: "Piani",
      stripe: {
        heading: "Gestisci abbonamento",
        description: "Clicca il pulsante Gestisci qui sotto per visualizzare o aggiornare le impostazioni del tuo abbonamento. Usa anche questa pagina per aggiornare i tuoi metodi di pagamento o annullare il tuo abbonamento se necessario.",
        managebtn: "Gestisci"
      },
      shopifyPlan: {
        shopifyAccess: "Accesso a Shopify",
        shopifyAccessYearly: "5% di sconto",
        perMonth: "Al mese",
        perYear: "All'anno",
        features: {
          feature1: "10.000 crediti chatbot/mese",
          feature2: "12.500 crediti WhatsApp/mese",
          feature3: "11.000.000 caratteri/chatbot",
          feature4: "Collegamenti illimitati per l'addestramento",
          feature5: "Accesso ai chatlog",
          feature6: "Integrazioni",
          feature7: "Accesso all'intervento umano",
          feature8: "Chatbot live nel tuo negozio Shopify"
        }
      },
      webPlan: {
        webAccess: "Accesso web",
        upgrade: "Aggiorna",
        switch_back: "Passa al piano mensile",
        current_plan: "Piano attuale",
        webAccessYearly: "5% di sconto",
        perMonth: "Al mese",
        perYear: "All'anno",
        features: {
          feature1: "10.000 crediti chatbot/mese",
          feature2: "12.500 crediti WhatsApp/mese",
          feature3: "Accesso ai chatlog",
          feature4: "400.000 caratteri/chatbot",
          feature5: "Accesso all'intervento umano",
          feature6: "Integrazioni",
          feature7: "Accesso alla funzione di tracciamento"
        }
      },
      credits: {
        chatbot: {
          heading: "Crediti aggiuntivi per messaggi chatbot",
          subHeading: "Crediti chatbot",
          per: "Per",
          modalHeading: "Ricarica crediti chatbot",
          modalsubHeading: "Crediti aggiuntivi che desidero acquistare:",
          modalPerCharge: "Totale per ricarica"
        },
        whatsapp: {
          heading: "Crediti aggiuntivi per messaggi WhatsApp",
          subHeading: "Crediti WhatsApp",
          modalHeading: "Ricarica crediti WhatsApp",
          modalsubHeading: "Crediti WhatsApp aggiuntivi che desidero acquistare:",
          modalPerCharge: "Totale per ricarica"
        },
        branding: {
          heading: "Rimuovi 'Sviluppato da Jaweb'",
          subHeading: "Rimuovi il branding di Jaweb dall'iFrame e dal widget",
          pay: "Paga $19",
          paid: "Pagato"
        },
        upgradeBtn: "Aggiorna"
      }
    },
    emailSummary: {
      label: "Riepilogo email",
      heading: "Imposta il logo aziendale per il riepilogo email",
      subHeading: "Nota: Si prega di utilizzare il formato .png con uno sfondo bianco",
      uploadImgBtn: "Carica immagine"
    },
    transactionHistory: {
      label: "Storico transazioni",
      table: {
        paymentAmt: "Importo pagamento (USD)",
        totalCredit: "Credito totale",
        creditType: "Tipo di credito",
        date: "Data"
      }
    }
  },
  botTraining: {
    heading: "Ottimizza l'intelligenza del tuo bot",
    subHeading: "Carica documenti PDF per l'apprendimento specializzato o un link a un sito web",
    uploadFile: "Carica file",
    uploadFileNote: "Seleziona i file desiderati e caricali",
    fetchLinksBtn: "Recupera link",
    pdf: "PDF",
    links: "Link",
    source: "Fonte",
    chars: "caratteri riconosciuti",
    totalChars: "Numero totale di caratteri riconosciuti",
    limit: "Limite",
    trainBot: "Allena il chatbot",
    chooseFile: "Seleziona un file o trascinalo qui",
    urlLink: "oppure incolla l'URL qui sotto",
    includeLinks: "Link inclusi",
    confirmDltBtn: "Conferma eliminazione",
    yesBtn: "Sì",
    noBtn: "No",
    confirmDltBtn: "Sei sicuro di voler eliminare questo?",
    confirmDltAll: "Conferma l'eliminazione di tutti",
    allLinksDltBtn: "Sei sicuro di voler eliminare tutti i link del sito web?",
    closeBtn: "Chiudi"
  },
  navbar: {
    customization: "Personalizzazione",
    leads: "Leads",
    analytics: "Analisi",
    message: "Messaggio",
    integration: "Integrazione",
    accSetting: "Impostazioni account",
    botTraining: "Formazione bot",
    shopify: "Shopify",
    team: "Team",
    help: "Aiuto",
    logout: "Disconnetti"
  },
  header: {
    whatsapp: "WhatsApp",
    chatbot: "Chatbot"
  }
}

    }
  }
})