import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import config from "../config";
import { Spin } from "antd";

const CheckSubscription = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPremium, setPremium] = useState(false);
  const [subMessage, setMessage] = useState('');
  const [invited, setIsInvited] = useState(false);
  const [shopifyAdminUrl, setShopifyAdminUrl] = useState(''); // For Shopify redirect

  useEffect(() => {
    checkUserAuth();
  }, []);

  const checkSubscription = async () => {
    const username = localStorage.getItem('username');
    const token = localStorage.getItem('token');

    if (username && token) {
      try {
        const subscriptionResponse = await fetch(`${config.apiUrl}subscription-status/`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (subscriptionResponse.ok) {
          const subscriptionData = await subscriptionResponse.json();
          let subscription;

          // Handle Stripe subscription
          if (subscriptionData.stripe_subscription) {
            subscription = subscriptionData.stripe_subscription;
            localStorage.setItem('price_id', subscription.items);

            if (subscription.status === "active" || subscription.status === "trialing") {
              setPremium(true);
            } else if (subscription.status === "past_due") {
              setPremium(false);
              setMessage("Your Stripe subscription is past due. Please update your billing information.");
            } else if (subscription.status === "canceled") {
              setPremium(false);
              setMessage("Your Stripe subscription has been canceled. Please update your billing information.");
            }
          } 
          // Handle Shopify subscription
          else if (subscriptionData.shopify_subscription) {
            subscription = subscriptionData.shopify_subscription;
            localStorage.setItem('shopify_plan', subscription.plan);

            if (subscription.plan !=="monthly" || subscription.plan !=="yearly"){
                localStorage.setItem('trial_end_date', subscription.trial_end_date);

            }

            if (subscription.status === "active") {
              setPremium(true); // Allow access
            } else if (subscription.status === "pending") {
              setMessage("Your Shopify subscription is pending. Please confirm the charge in your Shopify admin.");
              setShopifyAdminUrl(subscription.confirmation_url); // Redirect to confirmation URL
            } else if (subscription.status === "frozen") {
              setMessage("Your Shopify subscription is frozen due to non-payment. Please resolve it in Shopify admin.");
              setShopifyAdminUrl(subscription.confirmation_url); // Redirect to admin for payment resolution
            } else if (subscription.status === "declined") {
              setMessage("Your Shopify subscription was declined. Please update your payment in Shopify admin.");
              setShopifyAdminUrl(subscription.confirmation_url); // Redirect to admin for payment resolution
            } 
            else if (subscription.status === "canceled" && subscription.plan ==="free Trial ended") {
              setMessage("Your free trial has ended");
            }
            else if (subscription.status === "canceled") {
              setMessage("Your Shopify subscription has been canceled. Please take action in Shopify admin.");
              setShopifyAdminUrl(subscription.confirmation_url); // Redirect to admin for action
            }
        
            
          }
  
        } else {
          setPremium(false); // If subscription fetch fails
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
        setPremium(false);
      }
    }
    setIsLoading(false);
  };

  const checkUserAuth = async () => {
    if (localStorage.getItem('invited')) {
      setIsInvited(true);
      setIsLoading(false);
    } else {
      await checkSubscription();
    }
  };

  // Display loading spinner while checking subscription
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  // Redirect to Shopify Admin for necessary action based on the status
  if (shopifyAdminUrl) {
    window.location.href = shopifyAdminUrl;
    return null; // Return nothing while redirecting
  }

  // Redirect to billing page if not premium and not invited
  if (!isPremium && !invited) {
    return <Navigate to="/user/plans" state={{ message: subMessage }} />;
  }

  // Allow access if the subscription is valid or user is invited
  return <Outlet />;
};

export default CheckSubscription;
